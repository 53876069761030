import { useMemo, useState } from 'react'

import styled from '@emotion/styled'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { Box, Link, Typography } from '@mui/material'
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid'

import { useCurrentUser } from '@/hooks/auth'
import { ArrayElement } from '@/types'
import { APISupportTicketData, OptionSupportTabble, SupportFilter } from '@/types/support_ticket'

import SupportDatesCell from './SupportDatesCell'
import SupportStatusCell from './SupportStatusCell'
import SupportTableToolbar from './SupportTableToolbar'
import SupportTitleCell from './SupportTitleCell'
import SupportTypesCell from './SupportTypesCell'

const MainContainer = styled.div`
  display: flex;
  min-height: 500px;
  flex-direction: column;
`

const SupportTable: React.FC = () => {
  const queryClient = useQueryClient()
  const { data: helpTypeOptions } = useQuery<OptionSupportTabble[]>([
    'support_ticket',
    'help_type_options'
  ])
  const { data: urgencyTypeOptions } = useQuery<OptionSupportTabble[]>([
    'support_ticket',
    'urgency_type_options'
  ])
  const {
    user: currentUser,
    isLoading: isLoadingCurrentUser,
    isUserTeam: isCurrentUserTeam,
    isUserAdmin: isAdmin
  } = useCurrentUser()

  const [filters, setFilters] = useState<Partial<SupportFilter>>({
    status: 'pending'
  })

  const cacheKey = ['support_ticket']
  const { data: tickets, isInitialLoading: loading } = useQuery<APISupportTicketData>([
    'support_ticket',
    {
      id: !isLoadingCurrentUser && isCurrentUserTeam ? currentUser?.id : null,
      ...filters
    }
  ])

  const helpOptions: OptionSupportTabble[] = useMemo(() => {
    return helpTypeOptions ? helpTypeOptions : [{ label: '', value: '' }]
  }, [helpTypeOptions])

  const columns: GridColDef<ArrayElement<typeof rows>>[] = [
    {
      field: 'id',
      headerName: 'Nº do ticket',
      width: 120,
      editable: false,
      sortable: true,
      renderCell: function NameCell({ row }) {
        return (
          <Link href={`/support/${row.id}`}>
            <Typography align="center" variant="h6">
              {row.id}
            </Typography>
          </Link>
        )
      }
    },
    {
      field: 'title',
      headerName: 'Título',
      width: 240,
      editable: false,
      sortable: true,
      valueGetter({ row }) {
        return row.title
      },
      renderCell: function NameCell({ row }) {
        return <SupportTitleCell ticketDescription={row?.title} isLoading={loading} />
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      editable: isCurrentUserTeam || isAdmin,
      renderCell: function NameCell({ row }) {
        return <SupportStatusCell status={row?.status} supportTicketId={row?.id} />
      },
      renderEditCell: function NameCell({ row }) {
        return (
          <SupportStatusCell
            status={row?.status}
            supportTicketId={row?.id}
            supportTicketCacheKey={cacheKey}
            edit
          />
        )
      }
    },
    {
      field: 'help_type',
      headerName: 'Tipo de suporte',
      width: 130,
      editable: false,
      sortable: true,
      valueGetter({ row }) {
        return row.helpType
      },
      renderCell: function NameCell({ row }) {
        return (
          <SupportTypesCell
            ticketDescription={
              helpOptions.find((opt) => opt.value === row?.helpType)?.label as string
            }
            tooltipPrefix="O tipo de suporte é"
            helpType={row?.helpType}
            isLoading={loading}
          />
        )
      }
    },
    {
      field: 'urgency_type',
      headerName: 'Nível de urgência',
      width: 200,
      editable: false,
      sortable: true,
      valueGetter({ row }) {
        return row.urgencyType
      },
      renderCell: function NameCell({ row }) {
        return (
          <SupportTypesCell
            ticketDescription={
              urgencyTypeOptions?.find((opt) => opt.value === row?.urgencyType)?.label as string
            }
            tooltipPrefix="O nível de urgência é"
            helpType={row?.urgencyType}
            status={row?.status}
            isLoading={loading}
          />
        )
      }
    },
    {
      field: 'description',
      headerName: 'Descrição',
      width: 240,
      editable: false,
      sortable: true,
      valueGetter({ row }) {
        return row.description
      },
      renderCell: function NameCell({ row }) {
        return <SupportTitleCell ticketDescription={row?.description} isLoading={loading} />
      }
    },
    {
      field: 'name',
      headerName: 'Nome',
      width: 240,
      editable: false,
      sortable: true,
      valueGetter({ row }) {
        return row.name
      },
      renderCell: function NameCell({ row }) {
        return <SupportTitleCell ticketDescription={row?.name} isLoading={loading} />
      }
    },
    {
      field: 'brand',
      headerName: 'Empresa',
      width: 120,
      editable: false,
      sortable: true,
      valueGetter({ row }) {
        return row.brandName
      },
      renderCell: function NameCell({ row }) {
        return <SupportTitleCell ticketDescription={row.brandName} isLoading={loading} />
      }
    },
    {
      field: 'date',
      headerName: 'Tempo',
      width: 120,
      editable: false,
      sortable: true,
      valueGetter({ row }) {
        return row.createdAt
      },
      renderCell: function NameCell({ row }) {
        return (
          <SupportDatesCell
            dates={{ createdAt: row.createdAt, updatedAt: row.updatedAt }}
            isLoading={loading}
          />
        )
      }
    }
  ]

  const rows = useMemo(() => {
    if (!tickets?.data) return []
    return tickets?.data?.map((_ticket) => {
      return {
        description: _ticket.attributes.description,
        title: _ticket.attributes.title,
        status: _ticket.attributes.status,
        id: _ticket.id,
        helpType: _ticket.attributes.help_type,
        urgencyType: _ticket.attributes.urgency_type,
        name: _ticket.attributes.user.name,
        brandName: _ticket.attributes.user.brand_name as string,
        createdAt: _ticket.attributes.created_at,
        updatedAt: _ticket.attributes.updated_at
      }
    })
  }, [tickets])

  return (
    <MainContainer>
      <Box mt={2} />
      <DataGrid
        density="comfortable"
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows || []}
        components={{
          Toolbar() {
            return <SupportTableToolbar filters={filters} setFilters={setFilters} />
          }
        }}
        columns={columns}
        autoHeight
        pageSize={12}
        onCellEditStop={() => {
          queryClient.invalidateQueries(cacheKey)
        }}
        disableSelectionOnClick
        disableColumnMenu
        loading={loading}
      />
    </MainContainer>
  )
}

export default SupportTable
