import { InputBase } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'

/**
 * @example
 * <Search>
 *   <SearchIconWrapper>
 *     <SearchIcon />
 *   </SearchIconWrapper>
 *
 *   <StyledInputBase
 *     onChange={...}
 *     placeholder="Buscar..."
 *     inputProps={{ 'aria-label': 'search' }}
 *     onFocus={...}
 *     onBlur={...}
 *   />
 * </Search>
 */
export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  height: '65%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto'
  }
}))

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: 'white !important',
    [theme.breakpoints.up('sm')]: {
      width: '0px',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}))
