import { StrictMode, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import * as Sentry from '@sentry/react'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import DayJsIsBetween from 'dayjs/plugin/isBetween'
import DayJsPluginLocalizedFormat from 'dayjs/plugin/localizedFormat'
import DayJsPluginRelativeTime from 'dayjs/plugin/relativeTime'
import DayJsPluginTimezone from 'dayjs/plugin/timezone'
import DayJsPluginUTC from 'dayjs/plugin/utc'
import 'trix/dist/trix'

import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Container, CssBaseline, Toolbar } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import { EnvironmentLabel, YuToastProvider, truncatedText } from 'yu-open-lib'

import Routes from '@/Routes'
import { defaultQueryFn } from '@/api'
import api from '@/api/apiToken'
import YuAppBar from '@/components/shared/YuAppBar'
import Error500 from '@/components/static/Error500'
import { ProvideGlobalContext, ProvideNavBar, useGlobalContext } from '@/hooks'
import '@/i18n/config'
import '@/styles/main.scss'

import ProfilingBanner from './components/profiling/ProfilingBanner'
import { ProvideContainerContext, useContainerContext } from './hooks/container'
import { ProvideNpsLinkContext } from './hooks/nps'
import { usePersistReactQueryWithIndexedDB } from './hooks/usePersistReactQueryWithIndexedDB'
import { ProvideYuproContext } from './hooks/yupro'
import { theme } from './theme'
import { isAxiosError } from './types/api'

dayjs.locale('pt-br')
dayjs.extend(DayJsPluginRelativeTime)
dayjs.extend(DayJsPluginLocalizedFormat)
dayjs.extend(DayJsPluginTimezone)
dayjs.extend(DayJsPluginUTC)
dayjs.extend(DayJsIsBetween)

const queryCache = new QueryCache()
const queryClient = new QueryClient({
  queryCache,
  logger: {
    log() {
      // empty
    },
    warn() {
      // empty
    },
    error: (error: Error | AxiosError) => {
      if (!error?.message?.includes('404') && isAxiosError(error)) {
        // change later to a toast
        // eslint-disable-next-line no-console
        console.warn(
          `[Development Error] ${error?.message}: ${truncatedText(
            JSON.stringify(error?.response?.data),
            80
          )}`
        )
      }

      if (
        isAxiosError(error) &&
        error.response?.status === 403 &&
        error.response?.config?.method !== 'get'
      ) {
        // change later to a toast
        // eslint-disable-next-line no-console
        console.error(
          'Parece que você não tem autorização para fazer isso. Caso ache que isso está incorreto, contate a equipe de tecnologia'
        )
      }
    }
  },
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      notifyOnChangeProps: 'all',
      staleTime: 60 * 1000, // 1 minute
      // Store cache data for a maximum of 1h
      cacheTime: 1000 * 60 * 60
    }
  }
})

type ContainerWidthProps = {
  children: React.ReactNode
}

const ContainerWidth: React.FC<ContainerWidthProps> = ({ children }) => {
  const { maxWidth } = useContainerContext()

  return (
    <Container disableGutters maxWidth={maxWidth}>
      {children}
    </Container>
  )
}

const AppContainer = () => {
  const { environment } = useGlobalContext()
  const { i18n } = useTranslation()

  usePersistReactQueryWithIndexedDB(queryClient)

  if (i18n.language !== 'pt') {
    dayjs.locale('en')
    api.defaults.params = { ...api.defaults.params, locale: i18n.language }
  } else {
    dayjs.locale('pt-br')
    api.defaults.params = { ...api.defaults.params, locale: null }
  }

  return (
    <>
      <ProvideNavBar>
        <YuAppBar />

        {import.meta.env.DEV && <ProfilingBanner />}

        <ProvideContainerContext>
          <ContainerWidth>
            <Toolbar id="back-to-top-anchor" style={{ minHeight: 60 }} />

            {/* Don't remove, this div is used as a portal to display search results preview
            (candidates and jobs). */}
            <div id="search-result-portal-exit"></div>

            <YuToastProvider>
              <Routes />
            </YuToastProvider>
          </ContainerWidth>
        </ProvideContainerContext>
      </ProvideNavBar>

      <EnvironmentLabel environment={environment} margin={8} />
    </>
  )
}

const AppWithErrorBoundary = () => {
  const [boundaryError, setBoundaryError] = useState<Error>()

  return (
    <Sentry.ErrorBoundary
      fallback={<Error500 boundaryError={boundaryError} />}
      showDialog
      onError={(error) => {
        if (import.meta.env.DEV) {
          setBoundaryError(error)
        }
      }}
    >
      {import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}

      <StrictMode>
        <AppContainer />
      </StrictMode>
    </Sentry.ErrorBoundary>
  )
}

const App: React.FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <EmotionThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <QueryClientProvider client={queryClient}>
              <ProvideGlobalContext>
                <ProvideNpsLinkContext>
                  <ProvideYuproContext>
                    <AppWithErrorBoundary />
                  </ProvideYuproContext>
                </ProvideNpsLinkContext>
              </ProvideGlobalContext>
            </QueryClientProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </EmotionThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
