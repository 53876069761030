/* eslint-disable no-restricted-syntax */
import axios from 'axios'

import { history } from '@/routing'

import { API_BASE_URL } from './constant'

const api = axios.create({
  baseURL: API_BASE_URL,
  xsrfCookieName: 'CSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-Token',
  withCredentials: true
})

export interface JobDocument {
  name: string
  url: string
}

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      await api.delete('auth/logout')
      history.push('/users/sign_in')
      window.location.reload()
    }

    return Promise.reject(error)
  }
)

export default api
