import React, { createContext, useContext, useEffect, useRef } from 'react'

import { useParams } from 'react-router-dom'

import { NavbarItem } from 'yu-open-lib/dist/types'

import { Code, Help, Search, TextSnippet } from '@mui/icons-material'
import '@mui/icons-material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import BusinessIcon from '@mui/icons-material/Business'
import ChatIcon from '@mui/icons-material/Chat'
import CollectionsIcon from '@mui/icons-material/Collections'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EmailIcon from '@mui/icons-material/Email'
import GetAppIcon from '@mui/icons-material/GetApp'
import GroupIcon from '@mui/icons-material/Group'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import LockIcon from '@mui/icons-material/Lock'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import SchoolIcon from '@mui/icons-material/School'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import UpdateIcon from '@mui/icons-material/Update'
import WorkIcon from '@mui/icons-material/Work'

import { useCurrentUser } from '@/hooks/auth'

import { usePermission } from './permissions'

export const useTitle = (title?: string): void => {
  useEffect(() => {
    document.title = title ? `YURI - ${title}` : 'YURI'
  }, [title])
}

export type YuriNavbarItem = NavbarItem & {
  group?: 'more' | 'downItems'
}

export type NavBarItems = {
  drawerItems: YuriNavbarItem[]
  leftItems: YuriNavbarItem[]
  rightItems: YuriNavbarItem[]
}

export const useRequiredParams = <T extends Record<string, unknown>>(): T => useParams() as T

const navBarContext = createContext({
  drawerItems: [],
  leftItems: [],
  rightItems: []
} as NavBarItems)
export const useNavBarItems = (): NavBarItems => useContext(navBarContext)

type ProvideNavBarProps = {
  children?: React.ReactNode
}

export const ProvideNavBar: React.FC<ProvideNavBarProps> = ({ children }) => {
  const { user, isUserAdmin } = useCurrentUser()
  const canViewLogs = usePermission('versioning', 'manage')
  const canViewUsers = usePermission('user', 'manage')

  const adminDrawerItems: YuriNavbarItem[] = [
    {
      text: 'API Docs',
      path: '/api/docs',
      icon: <Code />,
      group: 'more'
    },
    {
      text: 'Import Tool',
      path: '/import_tool',
      icon: <GetAppIcon />,
      group: 'more'
    },
    {
      text: 'Templates de emails',
      path: '/email_templates',
      icon: <EmailIcon />,
      group: 'more'
    },
    {
      text: 'Painel Admin',
      path: '/admin-panel',
      icon: <SettingsApplicationsIcon />,
      group: 'more'
    },
    { text: 'Autorização', path: '/authorizations', icon: <LockIcon />, group: 'more' }
  ]

  const navBar: NavBarItems = {
    drawerItems: [
      { text: 'Busca Visual', path: '/visual_search/people', icon: <QueryStatsIcon /> },
      { text: 'Busca avançada', path: '/search/people', icon: <Search /> },
      { text: 'Tribos', path: '/tribes', icon: <CollectionsIcon /> },
      { text: 'Clientes', path: '/client_brands', icon: <HomeWorkIcon /> },
      { text: 'Time', path: '/team', icon: <GroupIcon /> },
      { text: 'Projetos', path: '/projects', icon: <AccountTreeIcon />, group: 'more' },
      { text: 'Dashboards', path: '/dashboards', icon: <DashboardIcon />, group: 'more' },
      { text: 'Alinhamentos', path: '/alignments', icon: <ChatIcon />, group: 'more' },
      {
        text: 'Criar Vaga',
        path: '/job_wizard',
        icon: <CreateNewFolderIcon />,
        group: 'more'
      },
      { text: 'Marcas', path: '/brands', icon: <BusinessIcon />, group: 'more' },
      { text: 'Mentorias ativas', path: '/mentorships', icon: <SchoolIcon />, group: 'more' },
      { text: 'Candidatos placed', path: '/placeds', icon: <WorkIcon />, group: 'more' },
      {
        text: 'Candidatos stand-by',
        path: '/standby_candidates',
        icon: <UpdateIcon />,
        group: 'more'
      },
      {
        text: 'Painel Produtos',
        path: '/product_settings',
        icon: <SettingsApplicationsIcon />,
        group: 'more'
      },
      {
        text: 'Signoff Pendentes',
        path: '/pending_signoffs',
        icon: <GroupIcon />,
        group: 'more'
      },
      { text: 'Vagas', path: '/jobs', icon: <WorkIcon />, group: 'downItems' },
      { text: 'Projetos Naikan', path: '/naikan', icon: <SchoolIcon />, group: 'downItems' },
      { text: 'Posts', path: '/posts', icon: <TextSnippet />, group: 'downItems' },
      { text: 'Dúvida/Ajuda', path: '/support', icon: <Help />, group: 'downItems' }
    ],
    leftItems: [],
    rightItems: []
  }

  if (canViewLogs) {
    navBar.drawerItems = [
      ...navBar.drawerItems,
      { text: 'Logs', path: '/logs', icon: <AccountTreeIcon />, group: 'more' }
    ]
  }

  if (canViewUsers) {
    navBar.drawerItems = [
      ...navBar.drawerItems,
      { text: 'Usuários', path: '/internal/users', icon: <AccountCircleIcon />, group: 'more' }
    ]
  }

  if (user?.person_id) {
    navBar.drawerItems = [
      { text: 'Meu Perfil', path: `/people/${user?.person_id}`, icon: <AccountCircleIcon /> },
      ...navBar.drawerItems
    ]
  }

  if (isUserAdmin) {
    navBar.drawerItems = navBar.drawerItems.concat(adminDrawerItems)
  }

  return <navBarContext.Provider value={navBar}>{children}</navBarContext.Provider>
}

const globalContext = createContext({} as GlobalContextValues)

export type Environment = 'development' | 'production' | 'staging'

export type GlobalContextValues = {
  environment: Environment
  clientAreaUrl: string
  candidateAreaUrl: string
  yuriApiUrl: string
}

export const useGlobalContext = (): GlobalContextValues => useContext(globalContext)

type ProvideGlobalContextProps = {
  children?: React.ReactNode
}

export const ProvideGlobalContext: React.FC<ProvideGlobalContextProps> = ({ children }) => {
  let environment: Environment = 'development'
  let clientAreaUrl = 'http://localhost:4000'
  let candidateAreaUrl = 'http://localhost:5000'
  let yuriApiUrl = 'http://localhost:3000'

  if (window.location.hostname.startsWith('yuri.jornadayu')) {
    environment = 'production'
    clientAreaUrl = 'https://clientes.jornadayu.com'
    candidateAreaUrl = 'https://jobs.jornadayu.com'
    yuriApiUrl = 'https://api.jornadayu.com'
  } else if (window.location.hostname.startsWith('staging')) {
    environment = 'staging'
    clientAreaUrl = 'https://clientes-staging.jornadayu.com'
    candidateAreaUrl = 'https://jobs-staging.jornadayu.com'
    yuriApiUrl = 'https://staging.api.jornadayu.com'
  }

  const context = {
    environment,
    clientAreaUrl,
    candidateAreaUrl,
    yuriApiUrl
  }

  return <globalContext.Provider value={context}>{children}</globalContext.Provider>
}

/**
 * Run a specific callback every X milliseconds, timer resets when the passed value is changed
 *
 * Useful for auto-save features when you want to have a slight delay before saving
 * so update requests are not spammed everytime a user changes an input
 *
 * ```tsx
 * const MyComponent = () => {
 *   const [value, setValue] = useState(null)
 *   useDelayedOnChange(value, saveForm, 1000)
 *
 *   const saveForm = () => {
 *     api.patch('records/123', { 'field': value })
 *   }
 *
 *   const handleChange = ({ target }) => setValue(value)
 *
 *   return (
 *     <input value={value} onChange={handleChange} name="field">
 *   )
 * }
 * ```
 */
export const useDelayedOnChange = (
  value: unknown,
  callback: () => void,
  period = 500,
  active = true,
  options = {
    allowEmpty: false
  }
): void => {
  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if ((!!value || options.allowEmpty) && active) {
      const timer = setTimeout(callback, period)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, active])
}
