export const userPermissions = {
  candidate_csv: {
    exportYuproCSV: 'export_candidates'
  },
  versioning: {
    manage: 'manage'
  },
  user: {
    impersonate: 'impersonate',
    impersonateLink: 'impersonate_link',
    manage: 'manage'
  }
}

export type UserPermissionsModel = keyof typeof userPermissions
