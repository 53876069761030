import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import { Card, CardProps, List, Skeleton, Stack, TextField, Typography } from '@mui/material'

import { useDebounce } from '@/hooks/forms'
import { APIYuriReleaseData } from '@/types/yuri_releases'

import YuriReleaseItem from './YuriReleaseItem'

const ReleaseSearch: React.FC<CardProps> = (props) => {
  const [title, setTitle] = useState<string>('')
  const [debouncedTitle, setDebouncedTitle] = useState<string>('')

  const { data: releases } = useQuery<APIYuriReleaseData>([
    'yuri_releases',
    { all: true, title: debouncedTitle }
  ])

  useDebounce(title, 1500, () => {
    setDebouncedTitle(title)
  })

  return (
    <Card variant="outlined" sx={{ flex: 1, p: 1, overflow: 'auto' }} {...props}>
      <Typography variant="h5">Releases</Typography>
      <Typography variant="subtitle2">
        Considere pesquisar seu problema nas releases antes de abrir o ticket
      </Typography>

      <TextField onChange={(e) => setTitle(e.target.value)} label="Pesquise" />

      {!releases?.data?.length ? (
        <Stack>
          <Skeleton width="100%" height="75px" />
          <Skeleton width="100%" height="75px" />
          <Skeleton width="100%" height="75px" />
          <Skeleton width="100%" height="75px" />
        </Stack>
      ) : (
        <List>
          {releases?.data?.map((release) => (
            <YuriReleaseItem release={release.attributes} key={`release-${release.id}`} />
          ))}
        </List>
      )}
    </Card>
  )
}

export default ReleaseSearch
