import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Hidden, IconButton, Toolbar, Typography } from '@mui/material'

import { HideOnScroll } from 'yu-open-lib'

import PlongeLogo from '@/assets/images/plonge_white_and_orange.png'
import YuriLogo from '@/assets/images/yuri_white.png'
import YuNavbarSearch from '@/components/shared/YuNavBarSearch'
import { useCurrentUser } from '@/hooks/auth'

import YuMatchScheduleButtonNavBar from './YuMatchScheduleButtonNavBar'

const YuMobileNavBar = ({ classes, toggleLeftDrawer }) => {
  const { isUserPlonge } = useCurrentUser()

  return (
    <Hidden mdUp>
      <HideOnScroll>
        <AppBar className={classes.appBar} size="large">
          <Toolbar className={classes.toolBar}>
            <Typography variant="h2" className={classes.title}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={toggleLeftDrawer}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Typography>

            <IconButton className={classes.logoYuriMobile} color="inherit" href="/" size="large">
              <img src={YuriLogo} width="30" height="30" />
              {isUserPlonge && <img src={PlongeLogo} width="32" height="32" />}
            </IconButton>

            <YuMatchScheduleButtonNavBar />
            <YuNavbarSearch />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Hidden>
  )
}

export default YuMobileNavBar
