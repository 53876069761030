import { createContext, useContext, useState } from 'react'

import { useToggle } from 'yu-open-lib'

import { BrandAttributes } from '@/types/brands'
import { Person } from '@/types/person'

export type YuproState = {
  yuproDialog: boolean
  toggleYuproDialog: () => void
  yuproPerson?: Person
  setYuproPerson: (person: Person) => void
  yuproBrand?: BrandAttributes
  setYuproBrand: (brand: BrandAttributes) => void
}

const yuproContext = createContext({} as YuproState)
export const useYuproContext = (): YuproState => useContext(yuproContext)

type ProvideYuproProps = {
  children?: React.ReactNode
}

export const ProvideYuproContext: React.FC<ProvideYuproProps> = ({ children }) => {
  const [yuproDialog, toggleYuproDialog] = useToggle(false)
  const [yuproPerson, setYuproPerson] = useState<Person>()
  const [yuproBrand, setYuproBrand] = useState<BrandAttributes>()

  const context: YuproState = {
    yuproDialog,
    toggleYuproDialog,
    yuproPerson,
    setYuproPerson,
    yuproBrand,
    setYuproBrand
  }

  return <yuproContext.Provider value={context}>{children}</yuproContext.Provider>
}
