import { useState } from 'react'

import dayjs from 'dayjs'

import {
  Avatar,
  Box,
  Button,
  Card,
  DialogContent,
  Link,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'

import { BaseDialog } from 'yu-open-lib'

import { YuriReleaseAttributes } from '@/types/yuri_releases'

type Props = {
  release: YuriReleaseAttributes
}

const YuriReleaseItem: React.FC<Props> = ({ release }) => {
  const [openImage, setOpenImage] = useState(false)
  const [image, setImage] = useState<string | null>(null)

  return (
    <>
      <Card variant="outlined">
        <ListItemButton
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'start',
            gap: 1,
            flexDirection: 'column'
          }}
        >
          <Stack direction="row" alignItems="center" gap={1} width="100%" flexWrap="wrap">
            {release.image_url ? (
              <Tooltip title="Clique para ver a imagem">
                <Avatar
                  src={release.image_url}
                  alt="Imagem"
                  sx={{
                    border: '2px solid',
                    borderColor: 'text.secondary',
                    cursor: 'pointer',
                    width: 50,
                    height: 50
                  }}
                  onClick={() => {
                    setImage(release.image_url)
                    setOpenImage(true)
                  }}
                />
              </Tooltip>
            ) : null}

            <Box>
              {release.url ? (
                <Link href={release.url} target="_blank">
                  <ListItemText primary={release.description || release.title} />
                </Link>
              ) : (
                <ListItemText primary={release.description || release.title} />
              )}
            </Box>

            <Box flex={1} />
            <Typography variant="subtitle2">{dayjs(release.created_at).fromNow()}</Typography>
          </Stack>
        </ListItemButton>
      </Card>
      <BaseDialog
        open={openImage}
        loading={image ? false : true}
        fullWidth
        maxWidth="lg"
        onClose={() => setOpenImage(false)}
        title=""
        actions={<Button onClick={() => setOpenImage(false)}>Fechar</Button>}
      >
        <DialogContent>
          <img src={image ? image : ''} alt="Imagem" width={1080} />
        </DialogContent>
      </BaseDialog>
    </>
  )
}

export default YuriReleaseItem
