import { createContext, useContext } from 'react'

import { RequestTrace } from '../types/traces'

export type ProfilerContextValues = {
  useProfilingTraces?: UseProfilingTraces
}

const profilerContext = createContext({} as ProfilerContextValues)

export type UseProfilingTraces = (options: {
  requestId: number | string
  /**
   * @default true
   */
  enabled?: boolean
}) => {
  isLoading: boolean
  isError: boolean
  data: RequestTrace[] | undefined
}
export const useProfilingContext = (): ProfilerContextValues => useContext(profilerContext)

export type ProvideProfilerProps = {
  useProfilingTraces: UseProfilingTraces
  children?: React.ReactNode
}

export const ProvideUseProfiler: React.FC<ProvideProfilerProps> = ({
  children,
  useProfilingTraces
}) => {
  const context: ProfilerContextValues = {
    useProfilingTraces
  }

  return <profilerContext.Provider value={context}>{children}</profilerContext.Provider>
}
