import { createContext, useContext, useState } from 'react'

import { useToggle } from 'yu-open-lib'

export type NpsLinkState = {
  setNpsLink: (link: string) => void
  npsLink: string | undefined
  npsDialog: boolean
  toggleNpsDialog: () => void
}

const npsLinkContext = createContext({} as NpsLinkState)
export const useNpsLinkContext = (): NpsLinkState => useContext(npsLinkContext)

type ProvideNpsLinkProps = {
  children?: React.ReactNode
}

export const ProvideNpsLinkContext: React.FC<ProvideNpsLinkProps> = ({ children }) => {
  const [npsLink, setNpsLink] = useState<undefined | string>()
  const [npsDialog, toggleNpsDialog] = useToggle(false)

  const context: NpsLinkState = {
    setNpsLink,
    npsLink,
    npsDialog,
    toggleNpsDialog
  }

  return <npsLinkContext.Provider value={context}>{children}</npsLinkContext.Provider>
}
