import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import enChatbot from '@/i18n/en/chatbot.json'
import enJob from '@/i18n/en/job.json'
import enJobApplication from '@/i18n/en/job_application.json'
import enYupro from '@/i18n/en/yupro.json'
import chatbot from '@/i18n/pt/chatbot.json'
import job from '@/i18n/pt/job.json'
import jobApplication from '@/i18n/pt/job_application.json'
import yupro from '@/i18n/pt/yupro.json'

export const defaultNS = 'job'

export const resources = {
  pt: {
    job,
    chatbot,
    yupro,
    job_application: jobApplication
  },
  en: {
    job: enJob,
    chatbot: enChatbot,
    yupro: enYupro,
    job_application: enJobApplication
  }
} as const

i18n
  .use(initReactI18next)
  .init({
    lng: 'pt',
    ns: ['job', 'chatbot', 'yupro', 'job_application'],
    defaultNS,
    resources
  })
  .then()
