import { useCallback, useState } from 'react'

import { useToasts } from 'react-toast-notifications'

import dayjs, { Dayjs } from 'dayjs'

import { Cancel, CheckCircle } from '@mui/icons-material'
import { LoadingButton, PickersDay, PickersDayProps, StaticDatePicker } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  CardHeader,
  Fade,
  Grid,
  List,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import { BaseDialog, ConfirmDialog, Loading } from 'yu-open-lib'

import { useCurrentUser } from '@/hooks/auth'
import { useAvailabilities } from '@/hooks/availabilities'

import {
  BottomCardActions,
  BottomCardActionsSeparator,
  FlexColumnCard
} from '../core/FlexColumnCard'
import { DateBadge } from './DateBadge'
import PeriodSelection, { availabilityComparison } from './PeriodSelection'

type Props = {
  userId: number | string
  open: boolean
  onClose: () => void
  personName: string
}

const UserAvailabilitiesDialog: React.FC<Props> = ({ personName, userId, open, onClose }) => {
  const { user } = useCurrentUser()
  const {
    hasUnsavedChanges,
    startTime,
    endTime,
    availabilities,
    periods,
    isLoading,
    date,
    setDate,
    openAccordion,
    setOpenAccordion,
    isSubmitLoading,
    submitDialog,
    toggleSubmitDialog,
    submitAvailabilities,
    localAvailabilities,
    setLocalAvailabilities,
    sendBlockerEmail
  } = useAvailabilities({
    userId,
    // Send calendar blocker emails only if the user is setting their own availabilities schedule
    enableBlockerEmails: user?.id?.toString() === userId?.toString()
  })

  const { addToast } = useToasts()

  const [sendBlockerEmailDialog, setSendBlockerEmailDialog] = useState(false)

  const handleSendBlockerEmail = () => {
    const ids = availabilities?.data?.map((availability) => availability.id) || []
    if (ids.length > 0) {
      sendBlockerEmail.mutateAsync(ids, {
        onSuccess: () => {
          addToast('E-mail enviado com sucesso!', { appearance: 'success' })
        },
        onError: () => {
          addToast('Ocorreu um erro ao enviar o e-mail.', { appearance: 'error' })
        }
      })
    }
    setSendBlockerEmailDialog(false)
  }

  const renderDay = useCallback(
    (
      dateToRender: Date | Dayjs,
      selectedDates: (Date | null)[],
      pickersDayProps: PickersDayProps<Date>
    ): React.ReactElement => {
      const formattedDate = dayjs(dateToRender).format('YYYY-MM-DD')

      const count =
        availabilities?.data.filter(
          (availability) =>
            dayjs(availability.attributes.start_at).format('YYYY-MM-DD') === formattedDate
        )?.length || 0

      const isWeekend = dayjs(dateToRender).day() === 0 || dayjs(dateToRender).day() === 6

      let borderColor = pickersDayProps.selected ? 'primary.dark' : 'common.white'
      if (pickersDayProps.disabled) {
        borderColor = 'gray'
      }

      return (
        <DateBadge
          color="success"
          sx={{
            '& .MuiBadge-badge': {
              height: 14,
              width: 14,
              borderRadius: 60
            }
          }}
          variant="dot"
          invisible={dayjs(dateToRender) <= dayjs() || count === 0}
        >
          <Tooltip title={hasUnsavedChanges ? 'Alterações não salvas' : ''}>
            <span>
              <PickersDay
                sx={{
                  border: '2px solid',
                  borderRadius: 2,
                  margin: 0.3,
                  borderColor,
                  textDecoration: pickersDayProps.disabled ? 'line-through' : 'none',
                  color: isWeekend ? 'text.secondary' : 'text.primary'
                }}
                {...pickersDayProps}
              />
            </span>
          </Tooltip>
        </DateBadge>
      )
    },
    [availabilities?.data, hasUnsavedChanges]
  )

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const renderPeriodSelection = useCallback(
    (index: number) => (
      <PeriodSelection
        key={index}
        dense={!isMobile}
        disabled={!availabilities}
        index={index}
        startTime={startTime}
        availabilities={availabilities}
        removeAvailability={(availability) => {
          setLocalAvailabilities((previous) => ({
            removed: [...previous.removed, availability],
            added: previous.added.filter(
              (av) =>
                !availabilityComparison(
                  av.start_at,
                  av.end_at,
                  availability.start_at,
                  availability.end_at
                )
            )
          }))
        }}
        addAvailability={(availability) => {
          setLocalAvailabilities((previous) => {
            const st = {
              added: [...previous.added, availability],
              removed: previous.removed.filter(
                (av) =>
                  !availabilityComparison(
                    av.start_at,
                    av.end_at,
                    availability.start_at,
                    availability.end_at
                  )
              )
            }

            return st
          })
        }}
        cancelAvailability={(availability) => {
          // Remove availability from either 'local.removed' or local.added
          setLocalAvailabilities((previous) => ({
            added: previous.added.filter(
              (av) =>
                !availabilityComparison(
                  av.start_at,
                  av.end_at,
                  availability.start_at,
                  availability.end_at
                )
            ),
            removed: previous.removed.filter(
              (av) =>
                !availabilityComparison(
                  av.start_at,
                  av.end_at,
                  availability.start_at,
                  availability.end_at
                )
            )
          }))
        }}
        localAvailabilities={localAvailabilities}
      />
    ),
    [availabilities, isMobile, localAvailabilities, startTime, setLocalAvailabilities]
  )

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={`Agenda de Disponibilidades para ${personName}`}
      maxWidth="xl"
      fullWidth
    >
      <Grid
        container
        spacing={1}
        sx={{
          px: {
            xl: 10,
            lg: 4,
            md: 0
          }
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <FlexColumnCard
            variant="outlined"
            sx={{
              height: 'calc(100vh - 130px)',
              overflowY: 'auto'
            }}
          >
            <ConfirmDialog
              open={submitDialog}
              handleClose={toggleSubmitDialog}
              handleConfirm={submitAvailabilities}
              cancelText="Cancelar"
              confirmText="Confirmar"
              confirmColor="primary"
              cancelColor="secondary"
              title="Confirmar agenda"
              renderConfirmButton={(props) => {
                return (
                  <LoadingButton
                    {...props}
                    loading={isSubmitLoading}
                    aria-label="Salvar nova Agenda"
                  >
                    Confirmar
                  </LoadingButton>
                )
              }}
            >
              <ul>
                {localAvailabilities.added.map((availability) => (
                  <li key={availability.start_at?.toISOString()}>
                    <Typography variant="body1">
                      {dayjs(availability.start_at).format('LLL')}
                      {' - '}
                      {dayjs(availability.end_at).format('HH:mm')}
                    </Typography>
                  </li>
                ))}

                {localAvailabilities.removed.map((availability) => (
                  <li key={availability.start_at?.toISOString()}>
                    <Typography
                      variant="body1"
                      sx={{
                        textDecoration: 'line-through'
                      }}
                    >
                      {dayjs(availability.start_at).format('LLL')}
                      {' - '}
                      {dayjs(availability.end_at).format('HH:mm')}
                    </Typography>
                  </li>
                ))}
              </ul>
            </ConfirmDialog>

            <StaticDatePicker
              loading={!availabilities}
              renderLoading={() => <Loading loading />}
              displayStaticWrapperAs="desktop"
              showToolbar
              componentsProps={{
                switchViewButton: {
                  sx: {
                    display: 'none'
                  }
                }
              }}
              label={`Agenda de ${personName}`}
              value={date || null}
              onChange={(dateChange) => setDate(dayjs(dateChange?.toDate()).toDate())}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore - renderDay typing is incorrect
              renderDay={renderDay}
              renderInput={(inputProps) => <TextField {...inputProps} />}
              disablePast
              // Only allow setting availabilities for the next three weeks, excluding current day
              // Typing for minDate and maxDate are incorrect, it receives a DayJS object, not a Date
              maxDate={dayjs(new Date()).add(3, 'weeks')}
              minDate={dayjs(new Date()).add(1, 'day')}
            />

            <BottomCardActionsSeparator />

            <BottomCardActions sx={{ border: 'none' }}>
              <Fade in={hasUnsavedChanges}>
                <Alert severity="info" variant="outlined" sx={{ m: 1, width: '100%' }}>
                  Alterações não salvas
                </Alert>
              </Fade>
            </BottomCardActions>
          </FlexColumnCard>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={8}>
          <FlexColumnCard
            variant="outlined"
            sx={{
              height: 'calc(100vh - 130px)',
              overflowY: 'auto',
              mb: {
                sm: 'initial',
                xs: 10
              }
            }}
          >
            <Fade in={!!date}>
              <CardHeader title="Horários" />
            </Fade>

            <Fade in={!!(date && startTime && endTime)}>
              <Box sx={{ px: 1 }}>
                <Accordion
                  expanded={openAccordion === 'morning'}
                  onChange={() =>
                    setOpenAccordion(openAccordion === 'morning' ? undefined : 'morning')
                  }
                >
                  <AccordionSummary>
                    <Typography>Manhã</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <List>{periods.map((_, index) => renderPeriodSelection(index))}</List>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={openAccordion === 'afternoon'}
                  onChange={() =>
                    setOpenAccordion(openAccordion === 'afternoon' ? undefined : 'afternoon')
                  }
                >
                  <AccordionSummary>
                    <Typography>Tarde</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <List>
                      {/* Skip first morning periods from index */}
                      {periods.map((_, index) => renderPeriodSelection(index + periods.length))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Fade>

            <BottomCardActionsSeparator />

            <BottomCardActions>
              <Grid container>
                <Grid item lg={8} md={6} xs={8}>
                  <LoadingButton
                    sx={{ m: 1, width: 'calc(100% - 16px)' }}
                    variant="outlined"
                    color="success"
                    onClick={toggleSubmitDialog}
                    endIcon={<CheckCircle />}
                    loading={isSubmitLoading}
                    disabled={!hasUnsavedChanges || isSubmitLoading || isLoading || !availabilities}
                    aria-label="Confirmar Alterações"
                  >
                    Confirmar
                  </LoadingButton>
                </Grid>

                <Grid item lg={4} md={6} xs={4}>
                  <LoadingButton
                    color="error"
                    endIcon={<Cancel />}
                    disabled={!hasUnsavedChanges}
                    loading={isSubmitLoading}
                    sx={{ m: 1, width: 'calc(100% - 16px)' }}
                    onClick={() => {
                      setLocalAvailabilities({
                        added: [],
                        removed: []
                      })
                    }}
                  >
                    Cancelar
                  </LoadingButton>
                </Grid>
                {!!availabilities && availabilities?.data?.length > 0 && (
                  <Grid item lg={8} md={6} xs={8}>
                    <LoadingButton
                      color="error"
                      variant="outlined"
                      loading={isSubmitLoading}
                      sx={{ m: 1, width: 'calc(100% - 16px)' }}
                      onClick={() => setSendBlockerEmailDialog(true)}
                    >
                      Enviar email com as disponibilidades
                    </LoadingButton>
                  </Grid>
                )}
                <ConfirmDialog
                  open={sendBlockerEmailDialog}
                  handleClose={() => setSendBlockerEmailDialog(false)}
                  title="Enviar email com as disponibilidades"
                  confirmText="Enviar"
                  cancelText="Cancelar"
                  handleConfirm={handleSendBlockerEmail}
                >
                  <Alert color="warning" severity="info">
                    Você irá enviar todas as disponibilidades para o email e não somente as novas
                    criadas.
                  </Alert>
                </ConfirmDialog>
              </Grid>
            </BottomCardActions>
          </FlexColumnCard>
        </Grid>
      </Grid>
    </BaseDialog>
  )
}

export default UserAvailabilitiesDialog
