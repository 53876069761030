import { Box, Skeleton, Tooltip, Typography } from '@mui/material'

type Props = {
  ticketDescription: string
  tooltipPrefix?: string
  isLoading?: boolean
}

const SupportTitleCell: React.FC<Props> = ({
  ticketDescription,
  isLoading,
  tooltipPrefix = 'Descrição'
}) => {
  if (isLoading) {
    return <Skeleton width={200} height={50} />
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Tooltip
        title={`${tooltipPrefix}: ${ticketDescription ? ticketDescription : ''}`}
        placement="top-start"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="caption">{ticketDescription ? ticketDescription : ''}</Typography>
        </Box>
      </Tooltip>
    </Box>
  )
}

export default SupportTitleCell
