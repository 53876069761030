import styled from '@emotion/styled'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Accordion as MuiAccordion,
  Theme,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  stickyHeader: {
    top: 0,
    left: 'auto',
    right: 0,
    position: 'sticky',
    zIndex: 99
  },
  header: {
    background: 'white'
  },
  root: {
    background: 'white'
  },
  accordionItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    '&:hover $editButton': {
      opacity: '1'
    }
  },
  accordionItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  accordionItemTitle: {
    background: 'white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  multipleChoiceOptions: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }: { theme?: Theme }) => ({
  border: `1px solid ${theme?.palette?.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

type Props = {
  expanded?: string | false
  handleChange?: (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => void
  expandIcon?: React.ReactElement
  titleIcon?: React.ReactElement
  href?: string
  title: string
  children?: React.ReactNode
  onClick?: () => void
}

const DrawerAccordion: React.FC<Props> = ({
  expanded,
  handleChange,
  href,
  expandIcon = <ExpandMoreIcon />,
  children,
  title,
  titleIcon
}) => {
  const classes = useStyles()

  const openLink = () => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href
    }).click()
  }

  return (
    <Accordion
      className={classes.root}
      expanded={expanded === title}
      onChange={href ? openLink : handleChange && handleChange(title)}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        className={`${expanded === title ? classes.stickyHeader : undefined} ${classes.header}`}
      >
        {titleIcon}
        <Typography style={{ marginLeft: titleIcon ? 30 : 0 }}>{title}</Typography>
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export default DrawerAccordion
