import { useMemo } from 'react'

import dayjs, { Dayjs } from 'dayjs'

import { ListItem, ListItemProps, ListItemText } from '@mui/material'

import { APIAvailabilityData, AvailabilityAttributes } from '@/types/availability'

type Props = {
  index: number
  startTime: Dayjs
  availabilities?: APIAvailabilityData
  removeAvailability: (availability: Partial<AvailabilityAttributes>) => void
  addAvailability: (availability: Partial<AvailabilityAttributes>) => void
  cancelAvailability: (availability: Partial<AvailabilityAttributes>) => void
  localAvailabilities: {
    added: Partial<AvailabilityAttributes>[]
    removed: Partial<AvailabilityAttributes>[]
  }
} & Partial<ListItemProps>

export const availabilityComparison = (
  startOne?: Date | Dayjs,
  endOne?: Date | Dayjs,
  startTwo?: Date | Dayjs,
  endTwo?: Date | Dayjs
): boolean => {
  if (!startOne || !endOne || !startTwo || !endTwo) {
    return false
  }

  return dayjs(startOne).isSame(startTwo) && dayjs(endOne).isSame(endTwo)
}

const PeriodSelection: React.FC<Props> = ({
  index,
  startTime,
  availabilities,
  removeAvailability,
  addAvailability,
  cancelAvailability,
  localAvailabilities,
  ...listItemProps
}) => {
  const start = startTime.add(index * 30, 'minute')
  const end = startTime.add((index + 1) * 30, 'minute')

  const availability = availabilities?.data.find((av) => {
    // Find availability where this checkbox range is within the start and end
    const startAv = dayjs(av.attributes.start_at)
    const endAv = dayjs(av.attributes.end_at)

    return (
      dayjs(start).isBetween(startAv, endAv, 'minute', '[]') &&
      dayjs(end).isBetween(startAv, endAv, 'minute', '[]')
    )
  })

  const dateObject = {
    start_at: start.toDate(),
    end_at: end.toDate(),
    id: availability?.id
  }

  // SÓ SELECIONA UM HORARIO POR VEZ, POIS OCORRE UM BUG SE SELECIONAR MAIS DE UM
  // TODO: VERIFICAR SE O BUG É NO FRONT OU NO BACK E CORRIGIR
  const remove = () =>
    localAvailabilities?.removed?.length < 1 ? removeAvailability(dateObject) : undefined
  const add = () => addAvailability(dateObject)
  const cancel = () => cancelAvailability(dateObject)

  const addedAvailability = localAvailabilities.added.find((av) =>
    availabilityComparison(start, end, av.start_at, av.end_at)
  )

  const removedAvailability = localAvailabilities.removed.find((av) =>
    availabilityComparison(start, end, av.start_at, av.end_at)
  )

  const bgColor = useMemo(() => {
    if (availability && removedAvailability) {
      return 'error.main'
    }
    if (availability) {
      return 'success.main'
    }
    if (addedAvailability) {
      return 'primary.main'
    }

    return undefined
  }, [addedAvailability, availability, removedAvailability])

  return (
    <ListItem
      sx={{
        borderRadius: 2,
        my: 0.6,
        bgcolor: bgColor,
        '&:hover': {
          bgcolor: bgColor,
          opacity: availability ? 0.8 : undefined
        }
      }}
      onClick={() => {
        if (addedAvailability || removedAvailability) {
          cancel()
        } else if (availability) {
          remove()
        } else {
          add()
        }
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Typing for 'button' forcefully becomes `false` when extending ListItem
      button
      {...listItemProps}
    >
      <ListItemText primary={`${start.format('HH:mm')} - ${end.format('HH:mm')}`} />
    </ListItem>
  )
}

export default PeriodSelection
