import { useMemo } from 'react'

import { useToasts } from 'react-toast-notifications'

import { QueryKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Property } from 'csstype'

import { Chip, FormControl, MenuItem, Select, Theme } from '@mui/material'
import { ResponsiveStyleValue, SystemStyleObject } from '@mui/system'

import api from '@/api/apiToken'
import { useCurrentUser } from '@/hooks/auth'
import { SelectOption } from '@/types/SelectOption'
import { MutationError } from '@/types/api'
import { SupportTicketAttributes, SupportTicketStatus } from '@/types/support_ticket'

type Props = {
  status: SupportTicketStatus
  supportTicketId: string | number
  supportTicketCacheKey?: QueryKey
  edit?: boolean
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>
}

const SupportStatusCell: React.FC<Props> = ({
  status,
  supportTicketId,
  edit = false,
  setEdit,
  supportTicketCacheKey
}) => {
  const queryClient = useQueryClient()

  const { addToast } = useToasts()
  const { data: statusOptions, isInitialLoading } = useQuery<SelectOption<SupportTicketStatus>[]>([
    'support_ticket',
    'status'
  ])
  const { isUserTeam: isCurrentUserTeam } = useCurrentUser()

  const statusOption = useMemo(() => {
    const options = statusOptions ? statusOptions : [{ value: '', label: '' }]
    const filtered_options = options.filter((option) => {
      if (isCurrentUserTeam) {
        return option.value == 'closed'
      } else {
        return true
      }
    })
    return filtered_options
  }, [statusOptions, isCurrentUserTeam])

  const updateJobPosition = useMutation(
    (newStatus: SupportTicketStatus) =>
      api.patch(`/support_ticket/${supportTicketId}`, { status: newStatus, attachment: null }),
    {
      onError(error: MutationError<SupportTicketAttributes>) {
        if (error?.response?.data?.full_errors?.length) {
          addToast(error.response.data.full_errors[0], { appearance: 'error' })
        } else {
          addToast('Houve um erro ao atualizar posição', { appearance: 'error' })
        }
        if (setEdit !== undefined) {
          setEdit(false)
        }
      },
      onSuccess() {
        addToast('Status da posição atualizada com sucesso!', { appearance: 'success' })
        queryClient.invalidateQueries(supportTicketCacheKey)
        if (setEdit !== undefined) {
          setEdit(false)
        }
      }
    }
  )

  const chipColor = useMemo<
    | SystemStyleObject<Theme>
    | ResponsiveStyleValue<Property.BackgroundColor | Property.BackgroundColor[]>
  >(() => {
    switch (status) {
      case 'closed':
        return 'success.main'
      case 'pending':
        return 'warning.main'
      default:
        return 'grey.500'
    }
  }, [status])

  if (edit) {
    return (
      <FormControl variant="filled" sx={{ minWidth: 150, mr: 1, ml: 2 }}>
        <Select
          variant="outlined"
          value={statusOptions ? statusOptions.find((opt) => opt.value === status)?.label : status}
          onChange={(event) => {
            updateJobPosition.mutate(event.target.value as SupportTicketStatus)
          }}
        >
          {statusOption?.map((opt, index) => (
            <MenuItem key={`${opt}-${index}`} value={opt.label}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  return (
    <>
      {isInitialLoading ? (
        ''
      ) : (
        <Chip
          label={statusOptions ? statusOptions.find((opt) => opt.value === status)?.label : status}
          sx={{
            color: 'white',
            bgcolor: chipColor,
            cursor: 'pointer',
            mr: 1,
            ml: 2
          }}
        />
      )}
    </>
  )
}

export default SupportStatusCell
