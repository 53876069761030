import { useQuery } from '@tanstack/react-query'

import { Avatar, AvatarProps, Tooltip } from '@mui/material'

type Props = {
  personId: number | string
  name?: string
} & AvatarProps

const PersonAvatar: React.FC<Props> = ({ name, personId, ...avatarProps }) => {
  const { data: avatarUrl } = useQuery<{ url: string }>(['users', 'avatar_url', personId], {
    refetchOnWindowFocus: false
  })

  return (
    <Tooltip arrow title={name || false}>
      <Avatar alt={name || personId.toString()} src={avatarUrl?.url} {...avatarProps} />
    </Tooltip>
  )
}

export default PersonAvatar
