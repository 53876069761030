import { AxiosError } from 'axios'

export interface ErrorMeta {
  error_messages?: string[]
  errors?: Record<keyof this, string[]>
}

export interface BaseAPISerializer<Attributes, Meta = ErrorMeta> {
  id: string
  type: string
  attributes: Attributes
  meta?: Meta & ErrorMeta
  success?: boolean
}

export interface DataAPISerializer<Data, Meta = void> {
  data: Data
  meta?: Meta
}

export type MutationError<T = void> = AxiosError<{
  error: string
  errors: Record<keyof T, string[]>
  full_errors?: string[]
}>

export const isAxiosError = (error: unknown): error is AxiosError => {
  if (error instanceof Error && 'response' in error) {
    return (error as AxiosError).isAxiosError === true
  }

  return (
    typeof error === 'object' &&
    error !== null &&
    'response' in error &&
    typeof (error as AxiosError).isAxiosError === 'boolean'
  )
}
