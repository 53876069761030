import { Badge, BadgeProps, styled } from '@mui/material'

export const DateBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 6,
    top: 6,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))

export const FutureDateBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    left: -15,
    top: 6
  }
}))
