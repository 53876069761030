import { useToasts } from 'react-toast-notifications'

import { useQuery } from '@tanstack/react-query'

import { FileCopy, Info } from '@mui/icons-material'
import { Box, Chip } from '@mui/material'

import { ElasticAppSearchCredentials } from '@/types/elastic/elastic_app_search'

const VisualPeopleSearch: React.FC = () => {
  const { addToast } = useToasts()
  const { data: credentialsData } = useQuery<ElasticAppSearchCredentials>([
    'elastic',
    'app_search_credentials'
  ])

  const copyUsername = () => {
    if (credentialsData?.yuri_user) {
      window.navigator.clipboard.writeText(credentialsData?.yuri_user)
      addToast('Username copiado para a área de transferência')
    } else {
      addToast('Houve um erro ao tentar copiar username', {
        appearance: 'error'
      })
    }
  }

  const copyPass = () => {
    if (credentialsData?.yuri_pass) {
      window.navigator.clipboard.writeText(credentialsData?.yuri_pass)
      addToast('Senha copiada para a área de transferência')
    } else {
      addToast('Houve um erro ao tentar copiar senha', {
        appearance: 'error'
      })
    }
  }

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: {
            sm: 13,
            xs: 65
          },
          zIndex: 99999,
          left: {
            xs: -10,
            sm: '10%',
            md: '30%',
            lg: '30%'
          }
        }}
      >
        <Chip
          color="primary"
          label="Utilize o primeiro botão abaixo para realizar login"
          icon={<Info />}
          sx={{
            display: {
              md: 'inline-flex',
              sm: 'none',
              xs: 'none'
            }
          }}
        />

        <Chip
          color="primary"
          label={
            <>
              <span style={{ fontWeight: 'bold' }}>Username:</span>{' '}
              <code>&apos;{credentialsData ? credentialsData?.yuri_user : '...'}&apos;</code>
            </>
          }
          clickable
          deleteIcon={<FileCopy />}
          sx={{ ml: 2 }}
          disabled={!credentialsData}
          onClick={copyUsername}
          onDelete={copyUsername}
        />
        <Chip
          label="Copiar senha"
          color="warning"
          deleteIcon={<FileCopy />}
          sx={{ ml: 2 }}
          disabled={!credentialsData}
          onClick={copyPass}
          onDelete={copyPass}
        />
      </Box>
      <iframe
        id="kibana-embed"
        src="https://yuri-elastic-search.kb.us-east-2.aws.elastic-cloud.com:9243/app/dashboards#/view/84ee93d0-e9f5-11ec-a7cf-257f3fe710c1?embed=true&_g=(filters%3A!())&show-query-input=true"
        style={{
          position: 'absolute',
          top: 59,
          right: 0,
          bottom: 0,
          left: -2,
          width: '100%',
          height: 'calc(100vh - 59px)'
        }}
      />
    </>
  )
}

export default VisualPeopleSearch
