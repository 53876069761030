/* eslint-disable no-restricted-syntax */
import { User } from '@sentry/react'
import * as Sentry from '@sentry/react'
import { QueryObserverResult, RefetchOptions, useQuery } from '@tanstack/react-query'

import { CurrentUserResponse, getCurrentUser } from '@/api/authenticaton'
import { getCookie } from '@/helpers/cookie'
import { ResponsibleCompany } from '@/types/jobs'
import { APIYuriUserData } from '@/types/users'

interface UseCurrentUserHook {
  user?: APIYuriUserData['attributes']
  loggedIn: boolean
  userRole?: APIYuriUserData['attributes']['role']
  userCanManage: boolean
  isUserYu: boolean
  isUserPlonge: boolean
  isUserAdmin: boolean
  isClient: boolean
  isUserTeam: boolean
  isAdminOrClient: boolean
  hasWorkloadAccess: boolean
  isLoading: boolean
  hasJob: boolean
  isConsultant?: boolean
  error: unknown
  responsibleCompany?: ResponsibleCompany
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<CurrentUserResponse, unknown>>
}

export const useCurrentUser = (): UseCurrentUserHook => {
  const { error, data, refetch, isInitialLoading } = useQuery(['user'], getCurrentUser, {
    cacheTime: 1000 * 60 * 15,
    staleTime: 1000 * 60 * 60
  })

  const user = data?.data?.attributes

  const isUserAdmin = user?.role === 'admin'
  const isUserYu = user?.role === 'team'
  const isClient = user?.role === 'client'
  const isAdminOrClient = !!user?.role && ['client', 'admin'].includes(user.role)
  const userCanManage = user?.role === 'team' || user?.role === 'partner' || user?.role === 'admin'
  const userRole = user?.role
  const isUserPlonge = user?.brand?.name === 'Plongê'
  const isUserTeam = user?.role === 'team'
  const isConsultant = user?.is_consultant
  const hasJob = !!user?.has_job
  const responsibleCompany = user?.responsible_company

  const hasWorkloadAccess = !!(!!user && (isConsultant || isUserAdmin))

  const loggedIn = !!getCookie('core_logged_in')

  if (user) {
    Sentry.setUser(user as User)
  }

  return {
    user,
    loggedIn,
    isClient,
    userRole,
    userCanManage,
    isAdminOrClient,
    isUserYu,
    isUserAdmin,
    isUserTeam,
    hasWorkloadAccess,
    isLoading: isInitialLoading,
    isUserPlonge,
    responsibleCompany,
    refetch,
    error,
    isConsultant,
    hasJob
  }
}
