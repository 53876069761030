import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { Replay } from '@sentry/replay'
import { BrowserTracing } from '@sentry/tracing'

/// <reference types="@welldone-software/why-did-you-render" />
import '../wdyr'
import App from './App'

type SentryEnvironment = 'development' | 'production' | 'staging'

if (import.meta.env.VITE_APP_SENTRY_DSN) {
  let environment: SentryEnvironment = import.meta.env.MODE

  if (window.location.hostname.includes('staging')) {
    environment = 'staging'
  }

  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new Replay({
        // Capture 10% of all sessions
        sessionSampleRate: 0.1,
        // Of the remaining 90% of sessions, if an error happens start capturing
        errorSampleRate: 1.0
      })
    ],
    ignoreErrors: [/.*ResizeObserver loop limit.*/],
    environment
  })
}
const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
