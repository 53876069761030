import { Suspense, lazy, useEffect } from 'react'

import { Navigate, Route, RouteProps, Routes as Switch, useLocation } from 'react-router-dom'

import { Toolbar } from '@mui/material'

import { BackdropLoading, CookiesWarning } from 'yu-open-lib'

import LinkedInPopUp from './components/linkedin/LinkedInPopUp'
import { useCurrentUser } from './hooks/auth'
import { useContainerContext } from './hooks/container'
import VisualPeopleSearch from './pages/search/visual/VisualPeopleSearch'
import HelpIndex from './pages/suportHelp/SupportIndex'

const SupportShow = lazy(() => import('@/pages/suportHelp/SupportShow'))
const TribeGroupPage = lazy(() => import('@/pages/tribes/TribeGroupPage'))
const ProductSettings = lazy(() => import('@/pages/product_settings/ProductSettings'))
const Alignments = lazy(() => import('@/pages/alignment/AlignmentsIndex'))
const Dashboards = lazy(() => import('@/pages/dashboard/DashboardsIndex'))
const ApiDocs = lazy(() => import('@/pages/docs/ApiDocs'))
const ProjectsIndex = lazy(() => import('@/pages/projects/ProjectsIndex'))
const JobShow = lazy(() => import('@/components/jobs/JobShow'))
const NewReferences = lazy(() => import('@/components/reference_checks/NewReferences'))
const SourcingAreaPage = lazy(() => import('@/components/jobs/SourcingAreaPage'))
const JobApplicationFallbacks = lazy(() => import('@/pages/job_applications/ApplicationFallbacks'))
const BrandsIndex = lazy(() => import('@/pages/brands/BrandsIndex'))
const JobsIndex = lazy(() => import('@/pages/jobs/JobsIndex'))
const HomePage = lazy(() => import('@/pages/home/HomePage'))
const JobRejections = lazy(() => import('@/pages/jobs/rejection_templates/JobRejections'))
const UsersIndex = lazy(() => import('@/pages/users/UsersIndex'))
const TribesIndex = lazy(() => import('@/pages/tribes/TribesIndex'))
const TribeGroupsIndex = lazy(() => import('@/pages/tribes/TribeGroupsIndex'))
const ImportTool = lazy(() => import('@/pages/import_tool/ImportTool'))
const BrandsShow = lazy(() => import('@/pages/brands/BrandsShow'))
const LogsPage = lazy(() => import('@/pages/logs/LogsPage'))
const AdminPage = lazy(() => import('@/pages/admin/AdminPage'))
const AuthorizationsPage = lazy(() => import('@/pages/admin/AuthorizationsPage'))
const AuthorizationPage = lazy(() => import('@/pages/admin/AuthorizationPage'))
const JobCreationWizardPage = lazy(() => import('@/pages/jobs/JobCreationWizardPage'))
const InterviewsPage = lazy(() => import('@/pages/meetings/InterviewsPage'))
const ProfileViews = lazy(() => import('@/pages/users/ProfileViews'))
const ClientsIndex = lazy(() => import('@/pages/clients/ClientsIndex'))
const PendingSignoffPage = lazy(() => import('@/pages/job_applications/PendingSignoffPage'))
const StandbyList = lazy(() => import('@/pages/job_applications/StandbyList'))
const PlacedList = lazy(() => import('@/pages/job_applications/PlacedList'))
const PeoplePage = lazy(() => import('@/pages/people/PeoplePage'))
const TribePage = lazy(() => import('@/pages/tribes/TribePage'))
const SignIn = lazy(() => import('@/pages/users/SignIn'))
const TeamIndex = lazy(() => import('@/pages/team/TeamIndex'))
const NaikanIndex = lazy(() => import('@/pages/naikan/NaikanIndex'))
const BrandsInterest = lazy(() => import('@/pages/brands_interest/BrandsInterestIndex'))
const PeopleSearch = lazy(() => import('@/pages/search/PeopleSearch'))
const VisibleBrands = lazy(() => import('@/pages/brands_preview/VisibleBrandsIndex'))
const LoginExtension = lazy(() => import('@/pages/auth/LoginExtension'))
const MagicLinkLogin = lazy(() => import('@/pages/auth/MagicLinkLogin'))
const MiniYuriLoading = lazy(() => import('@/pages/auth/MiniYuriLoading'))
const MicrosoftPopup = lazy(() => import('@/pages/auth/Microsoft/MicrosoftPopup'))
const GitHubPopUp = lazy(() => import('@/components/github/GitHubPopUp'))
const GooglePopUp = lazy(() => import('@/components/google/GooglePopUp'))
const YuriPosts = lazy(() => import('@/pages/posts/YuriPosts'))
const MaintenancePage = lazy(() => import('@/pages/MaintenancePage'))

/**
 * Redirect user to login if he's not already authenticated, then redirect
 * them back after they have finished logging in
 */
const PrivateRoute: React.FC<RouteProps> = ({ children }) => {
  const { loggedIn } = useCurrentUser()
  if (!loggedIn) {
    return <Navigate to="/users/sign_in" />
  }
  return children as React.ReactElement
}

const YuRoute: typeof Route = ({ children }) => (
  <PrivateRoute>
    <>
      <Toolbar style={{ minHeight: 20 }} />
      {children}
    </>
  </PrivateRoute>
)

type RouteObject = {
  path: `/${string}`
  component: React.ReactNode
  /** @default true */
  exact?: boolean
}

export const publicRoutes: RouteObject[] = [
  {
    path: '/users/sign_in',
    component: <SignIn />
  },
  {
    path: '/users/auth/google_oauth2/callback',
    component: <GooglePopUp />
  },
  {
    path: '/users/auth/github/callback',
    component: <GitHubPopUp />
  },
  {
    path: '/users/auth/linkedin/callback',
    component: <LinkedInPopUp />
  },
  {
    path: '/users/auth/microsoft/callback',
    component: <MicrosoftPopup />
  },
  {
    path: '/magic_link_login',
    component: <MagicLinkLogin />
  },
  {
    path: '/maintenance',
    component: <MaintenancePage />
  }
]

export const routes: RouteObject[] = [
  {
    path: '/',
    component: <HomePage />
  },
  {
    path: '/alignments',
    component: <Alignments />
  },
  {
    path: '/dashboards',
    component: <Dashboards />
  },
  {
    path: '/users/mini_yuri/sign_in',
    component: <LoginExtension />
  },
  {
    path: '/users/mini_yuri/loading',
    component: <MiniYuriLoading />
  },
  {
    path: '/jobs',
    component: <JobsIndex />
  },
  {
    path: '/tribes',
    component: <TribesIndex />
  },
  {
    path: '/tribe_groups',
    component: <TribeGroupsIndex />
  },
  {
    path: '/tribe_groups/:id',
    component: <TribeGroupPage />
  },
  {
    path: '/tribes/:id',
    component: <TribePage />
  },
  {
    path: '/internal/users',
    component: <UsersIndex />
  },
  {
    path: '/people/:personId',
    component: <PeoplePage />
  },
  {
    path: '/interviews/:id',
    component: <InterviewsPage />
  },
  {
    path: '/admin-panel',
    component: <AdminPage />
  },
  {
    path: '/authorizations',
    component: <AuthorizationsPage />
  },
  {
    path: '/authorizations/:id',
    component: <AuthorizationPage />
  },
  {
    path: '/jobs/:slug',
    component: <JobShow />
  },
  {
    path: '/brands',
    component: <BrandsIndex />
  },
  {
    path: '/import_tool',
    component: <ImportTool />
  },
  {
    path: '/client_brands',
    component: <ClientsIndex />
  },
  {
    path: '/profile_views/:id',
    component: <ProfileViews />
  },
  {
    path: '/brands/:id',
    component: <BrandsShow />
  },
  {
    path: '/job_wizard/:slug?',
    component: <JobCreationWizardPage />
  },
  {
    path: '/sourcing_area',
    component: <SourcingAreaPage />
  },
  {
    path: '/professional_references/:uuid',
    component: <NewReferences />
  },
  {
    path: '/jobs/:slug/job_application_fallbacks',
    component: <JobApplicationFallbacks />
  },
  {
    path: '/rejection_templates/:slug',
    component: <JobRejections />
  },
  {
    path: '/pending_signoffs',
    component: <PendingSignoffPage />
  },
  {
    path: '/standby_candidates',
    component: <StandbyList />
  },
  {
    path: '/placeds',
    component: <PlacedList />
  },
  {
    path: '/team',
    component: <TeamIndex />
  },
  {
    path: '/naikan',
    component: <NaikanIndex />
  },
  {
    path: '/api/docs',
    component: <ApiDocs />
  },
  {
    path: '/projects',
    component: <ProjectsIndex />
  },
  {
    path: '/brands_interest',
    component: <BrandsInterest />
  },
  {
    path: '/product_settings',
    component: <ProductSettings />
  },
  {
    path: '/search/people',
    component: <PeopleSearch />
  },
  {
    path: '/visual_search/people',
    component: <VisualPeopleSearch />
  },
  {
    path: '/visible_brands',
    component: <VisibleBrands />
  },
  {
    path: '/support',
    component: <HelpIndex />
  },
  {
    path: '/support/:id',
    component: <SupportShow />
  },
  {
    path: '/posts',
    component: <YuriPosts />
  },
  {
    path: '/logs',
    component: <LogsPage />
  }
]

const Routes: React.FC = () => {
  const { pathname } = useLocation()
  const { setMaxWidth } = useContainerContext()

  useEffect(() => {
    setMaxWidth(pathname === '/users/sign_in' ? false : undefined)
  }, [pathname, setMaxWidth])

  return (
    <Suspense fallback={<BackdropLoading open />}>
      <CookiesWarning url="http://jornadayu.com/politica-de-privacidade/" />
      <Switch>
        {routes.map(({ path, component }) => (
          <Route path={path} key={path} element={<YuRoute>{component}</YuRoute>} />
        ))}
        {publicRoutes.map(({ path, component, exact }) => (
          <Route
            key={path}
            path={path}
            caseSensitive={exact === undefined ? true : exact}
            element={component}
          />
        ))}
      </Switch>
    </Suspense>
  )
}

export default Routes
