import { useMemo, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { NotificationItem, NotificationMenu } from 'yu-open-lib'

import api from '@/api/apiToken'
import { useCurrentUser } from '@/hooks/auth'
import { APINotificationData, NotificationAttributes } from '@/types/notifications'

const NotificationMenuButton: React.FC = () => {
  const [NotifyAnchorEl, setNotifyAnchorEl] = useState<
    null | Element | (EventTarget & SVGSVGElement)
  >(null)

  const queryClient = useQueryClient()
  const { loggedIn } = useCurrentUser()

  const notificationCacheKey = ['client_area', 'notifications']
  const { data: notifications } = useQuery<APINotificationData>(notificationCacheKey, {
    enabled: loggedIn
  })

  const notificationsData: NotificationAttributes[] = useMemo(() => {
    if (!notifications) return []
    return notifications?.data.map((n) => ({
      id: n.id,
      title: n.attributes.title,
      description: n.attributes.description,
      viewed: n.attributes.viewed,
      created_at: n.attributes.created_at,
      updated_at: n.attributes.updated_at,
      viewed_at: n.attributes.viewed_at,
      notifiable_id: n.attributes.notifiable_id,
      notifiable_type: n.attributes.notifiable_type,
      notifiable_uuid: n.attributes.notifiable_uuid,
      notification_type: n.attributes.notification_type
    }))
  }, [notifications])

  const notificationMutation = useMutation(
    async (id: (string | number)[]) => {
      await api.post('client_area/notifications/mark_as_viewed', { ids: id })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(notificationCacheKey)
      }
    }
  )

  const handleNotification = (ids: (string | number)[]) => {
    const notifys = ids.map((i) => notifications?.data.find((n) => n.id === i))
    if (!notifys) return
    notifys.forEach((n) => {
      if (n?.attributes?.viewed) {
        ids.splice(ids.indexOf(n.id), 1)
      }
    })
    if (ids.length === 0) return
    notificationMutation.mutate(ids)
  }

  return (
    <NotificationMenu
      title="Notificações"
      emptyNotificationText="Nenhuma notificação"
      markAllAsReadText="Marcar todas as notificações como lidas"
      showOnlyUnreadText="Mostrar apenas notificação não lidas"
      notifications={notificationsData || []}
      handleNotifications={handleNotification}
      open={Boolean(NotifyAnchorEl)}
      onClose={() => setNotifyAnchorEl(null)}
      setAnchorEl={setNotifyAnchorEl}
      anchorEl={NotifyAnchorEl}
      NotificationItem={(props) => <NotificationItem {...props} actionButton={undefined} />}
    />
  )
}

export default NotificationMenuButton
