import { createContext, useContext, useState } from 'react'

type Container = {
  maxWidth?: false
  setMaxWidth: (maxWidth: false | undefined) => void
}

const containerContext = createContext({} as Container)
export const useContainerContext = (): Container => useContext(containerContext)

type ProvideContainerProps = {
  children?: React.ReactNode
}

export const ProvideContainerContext: React.FC<ProvideContainerProps> = ({ children }) => {
  const [maxWidth, setMaxWidth] = useState<false | undefined>(false)

  const context: Container = {
    maxWidth,
    setMaxWidth
  }

  return <containerContext.Provider value={context}>{children}</containerContext.Provider>
}
