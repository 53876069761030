import { useLayoutEffect } from 'react'

/**
 * Shows alert when leaving tab, if the prop passed is true, usually to be used for
 * unsaved changes in a form, for example.
 */
export const useUnsavedChanges = (hasUnsavedChanges?: boolean): void => {
  useLayoutEffect(() => {
    // Check for unsaved changes when closing tab
    const oldBeforeUnload = window.onbeforeunload

    window.onbeforeunload = () => {
      if (hasUnsavedChanges) {
        return true
      }

      return undefined
    }

    return () => {
      window.onbeforeunload = oldBeforeUnload
    }
  }, [hasUnsavedChanges])
}
