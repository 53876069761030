import { useLayoutEffect, useRef, useState } from 'react'

import ReactDOM from 'react-dom'

import { useQuery } from '@tanstack/react-query'

import SearchIcon from '@mui/icons-material/Search'

import api from '@/api/apiToken'
import SearchBarResults from '@/components/shared/SearchBarResults'
import { useDebounce } from '@/hooks/forms'
import { JobPeopleSearch } from '@/types/search'

import { Search, SearchIconWrapper, StyledInputBase } from './inputs/Search'

const YuNavbarSearch: React.FC = () => {
  const [searchWasBlurred, setSearchWasBlurred] = useState(true)
  const [isMouseHoveringDropdown, setIsMouseHoveringDropdown] = useState(false)
  const [inputSearchQuery, setInputSearchQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [showResultsPreview, setShowResultsPreview] = useState(false)
  const portalExitElementRef = useRef<HTMLElement | null | undefined>()

  useLayoutEffect(() => {
    portalExitElementRef.current = document.getElementById('search-result-portal-exit')
  }, [])

  const { data: searchData, isLoading, isInitialLoading } = useQuery(
    ['people', 'search', 'preview', searchQuery],
    () => {
      return api.post<JobPeopleSearch>('/retrieve_search_results_preview', {
        search_query: searchQuery
      })
    },
    {
      enabled: !!searchQuery,
      refetchOnWindowFocus: false,
      onSuccess() {
        setShowResultsPreview(true)
      }
    }
  )

  useDebounce(inputSearchQuery, 1000, () => {
    setSearchQuery(inputSearchQuery)
  })

  const handleSearchQueryChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = ({ target }) => {
    setInputSearchQuery(target.value)
    setShowResultsPreview(false)
  }

  const handleShowResultsPreview: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setSearchWasBlurred(false)
    if (event.target.value) {
      setShowResultsPreview(true)
    }
  }

  function hideResultsPreview() {
    setSearchWasBlurred(true)
    if (!isMouseHoveringDropdown) {
      setTimeout(() => setShowResultsPreview(false), 500)
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const hasData = !!searchData || !!searchData?.data?.jobs?.data?.length
  const renderPreview = showResultsPreview && hasData

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>

        <StyledInputBase
          onChange={handleSearchQueryChange}
          placeholder="Buscar..."
          inputProps={{ 'aria-label': 'search' }}
          onFocus={handleShowResultsPreview}
          onBlur={hideResultsPreview}
        />
      </Search>

      {(isLoading || renderPreview || isInitialLoading) &&
        portalExitElementRef.current &&
        !!searchQuery &&
        ReactDOM.createPortal(
          <SearchBarResults
            isLoading={isLoading}
            peopleToSuggest={searchData?.data?.people?.data || []}
            jobsToSuggest={searchData?.data?.jobs?.data || []}
            searchQuery={searchQuery}
            setIsMouseHoveringDropdown={setIsMouseHoveringDropdown}
            setShowResultsPreview={setShowResultsPreview}
            searchWasBlurred={searchWasBlurred}
          />,
          portalExitElementRef.current
        )}
    </>
  )
}

export default YuNavbarSearch
