import { useMemo } from 'react'

import { Property } from 'csstype'

import { Box, Chip, Skeleton, Theme, Tooltip } from '@mui/material'
import { ResponsiveStyleValue, SystemStyleObject } from '@mui/system'

import {
  SupportTicketPriority,
  SupportTicketStatus,
  SupportTicketType
} from '@/types/support_ticket'

type Props = {
  ticketDescription: string
  tooltipPrefix?: string
  helpType: SupportTicketType | SupportTicketPriority
  status?: SupportTicketStatus
  isLoading?: boolean
}

const SupportTypesCell: React.FC<Props> = ({
  ticketDescription,
  tooltipPrefix = 'Descrição',
  helpType,
  status,
  isLoading
}) => {
  const chipColor = useMemo<
    | SystemStyleObject<Theme>
    | ResponsiveStyleValue<Property.BackgroundColor | Property.BackgroundColor[]>
  >(() => {
    switch (helpType) {
      case 'high':
        if (status === 'closed' || status === 'canceled') {
          return 'grey.500'
        }
        return 'error.main'
      default:
        return 'grey.500'
    }
  }, [helpType, status])

  if (isLoading) {
    return <Skeleton width={200} height={50} />
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Tooltip
        title={`${tooltipPrefix}: ${ticketDescription ? ticketDescription : ''}`}
        placement="top-start"
      >
        <Chip
          label={ticketDescription ? ticketDescription : ''}
          sx={{
            color: 'white',
            bgcolor: chipColor
          }}
        />
      </Tooltip>
    </Box>
  )
}

export default SupportTypesCell
