import React from 'react'
import { useLayoutEffect } from 'react'

import { ThemeProvider } from '@mui/material/styles'

import { AppTheme } from 'yu-open-lib'

export const theme = AppTheme({
  darkMode: false,
  options: {
    typography: {
      fontFamily: '"Helvetica Neue", Helvetica',
      h1: {
        fontFamily: 'Integral CF Bold'
      },
      h2: {
        fontFamily: 'Integral CF Bold'
      },
      h3: {
        fontFamily: 'Integral CF Bold'
      },
      h5: {
        fontFamily: 'DM Sans'
      }
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none'
          }
        }
      },
      MuiSpeedDialAction: {
        styleOverrides: {
          staticTooltipLabel: {
            whiteSpace: 'nowrap',
            maxWidth: 'none'
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard'
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: 'Integral CF Bold'
          }
        }
      },
      MuiFab: {
        styleOverrides: {
          root: {
            borderRadius: '180px !important'
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: '180px !important'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '4px !important'
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff'
          }
        }
      }
    },
    palette: {
      background: {
        default: '#fff',
        paper: '#fff'
      }
    }
  }
})

export const darkTheme = AppTheme({
  darkMode: true,
  options: {
    typography: {
      h1: {
        fontFamily: 'Integral CF Bold'
      },
      h2: {
        fontFamily: 'Integral CF Bold'
      },
      h3: {
        fontFamily: 'Integral CF Bold'
      }
    },
    palette: {
      primary: {
        main: '#127baf',
        dark: '#125c8a',
        light: '##2791be',
        contrastText: '#f1f1f1'
      },
      background: {
        default: '#0c1c2c',
        paper: '#0a1929'
      }
    },
    components: {
      ...theme.components,
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: '#0a1929'
          }
        }
      }
    }
  }
})

export const useWitheBackground = (): void => {
  useLayoutEffect(() => {
    const background = document.body.style.backgroundColor

    document.body.style.backgroundColor = theme.palette.common.white

    return () => {
      document.body.style.backgroundColor = background
    }
  }, [])
}

export const useActionBackground = (): void => {
  useLayoutEffect(() => {
    const background = document.body.style.backgroundColor

    document.body.style.backgroundColor = theme.palette.divider

    return () => {
      document.body.style.backgroundColor = background
    }
  }, [])
}

type Props = {
  /** @default true */
  withBackground?: boolean
  children?: React.ReactNode
}

export const WithDarkTheme: React.FC<Props> = ({ children, withBackground = true }) => {
  useLayoutEffect(() => {
    if (!withBackground) return

    const background = document.body.style.backgroundColor

    document.body.style.backgroundColor = darkTheme.palette.background.default

    return () => {
      document.body.style.backgroundColor = background
    }
  }, [withBackground])

  return <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>
}
