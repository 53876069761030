import dayjs from 'dayjs'

import { Box, Skeleton, Tooltip, Typography } from '@mui/material'

type Props = {
  dates: { createdAt?: Date; updatedAt?: Date }
  isLoading?: boolean
}

const SupportDatesCell: React.FC<Props> = ({ dates, isLoading }) => {
  const diffDateFromStatusDate = dayjs().diff(dayjs(dates.updatedAt || dates.createdAt), 'day')

  if (isLoading) {
    return <Skeleton width={200} height={50} />
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Tooltip
        title={dayjs(dates.updatedAt || dates.createdAt).format('DD/MM/YYYY')}
        placement="top-start"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="caption">
            {diffDateFromStatusDate > 1
              ? `Há ${diffDateFromStatusDate} dias.`
              : `Há ${diffDateFromStatusDate} dia.`}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  )
}

export default SupportDatesCell
