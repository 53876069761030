import { useCallback, useState } from 'react'

import { useToasts } from 'react-toast-notifications'

import * as Sentry from '@sentry/react'
import { UseMutationResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'

import { useToggle } from 'yu-open-lib'

import api from '@/api/apiToken'
import { useUnsavedChanges } from '@/hooks/events'
import { APIAvailabilityData, AvailabilityAttributes } from '@/types/availability'

export type LocalAvailabilities = {
  added: Partial<AvailabilityAttributes>[]
  removed: Partial<AvailabilityAttributes>[]
}

export const useAvailabilities = ({
  userId,
  enableBlockerEmails = false
}: {
  userId: number | string
  /**
   * @default false
   */
  enableBlockerEmails?: boolean
}): {
  hasUnsavedChanges: boolean
  startTime: dayjs.Dayjs
  endTime: dayjs.Dayjs
  availabilities: APIAvailabilityData | undefined
  periods: number[]
  isLoading: boolean
  date: Date | null | undefined
  setDate: React.Dispatch<React.SetStateAction<Date | null | undefined>>
  openAccordion: 'afternoon' | 'morning' | undefined
  setOpenAccordion: React.Dispatch<React.SetStateAction<'afternoon' | 'morning' | undefined>>
  isSubmitLoading: boolean
  submitDialog: boolean
  toggleSubmitDialog: () => void
  submitAvailabilities: () => Promise<void>
  localAvailabilities: LocalAvailabilities
  setLocalAvailabilities: React.Dispatch<React.SetStateAction<LocalAvailabilities>>
  sendBlockerEmail: UseMutationResult<AxiosResponse<any>, unknown, (string | number)[], unknown>
} => {
  const { data: availabilities, isInitialLoading } = useQuery<APIAvailabilityData>([
    'client_area',
    'meetings',
    'availabilities',
    {
      user_id: userId
    }
  ])
  const [date, setDate] = useState<Date | null>()
  const [openAccordion, setOpenAccordion] = useState<'afternoon' | 'morning' | undefined>('morning')

  const addAvailability = useMutation((props: Partial<AvailabilityAttributes>) =>
    api.post('jobs/meetings/availabilities', { ...props, user_id: userId })
  )

  const sendBlockerEmail = useMutation(
    ['availabilities', 'send_blocker_email'],
    (availabilityIds: (number | string)[]) =>
      api.post('client_area/meetings/availabilities/send_blocker_emails', {
        availability_ids: availabilityIds
      })
  )

  const removeAvailability = useMutation((props: Partial<AvailabilityAttributes>) => {
    return api.delete(
      `jobs/meetings/availabilities/${
        props.id
      }?user_id=${userId}&start_at=${props.start_at?.toISOString()}&end_at=${props.end_at?.toISOString()}`
    )
  })

  const [localAvailabilities, setLocalAvailabilities] = useState<{
    added: Partial<AvailabilityAttributes>[]
    removed: Partial<AvailabilityAttributes>[]
  }>({ added: [], removed: [] })

  const { addToast } = useToasts()

  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const queryClient = useQueryClient()

  const [submitDialog, toggleSubmitDialog] = useToggle(false)

  const submitAvailabilities = useCallback(async () => {
    try {
      setIsSubmitLoading(true)

      // Wait for all requests of removing/adding availabilities
      await Promise.all([
        ...localAvailabilities.added.map((availability) =>
          addAvailability.mutateAsync(availability)
        ),
        ...localAvailabilities.removed.map((availability) =>
          removeAvailability.mutateAsync(availability)
        )
      ])

      queryClient.invalidateQueries(['client_area', 'meetings', 'availabilities'])
      toggleSubmitDialog()
      addToast('Agenda salva com sucesso')

      const localAvailabilitiesCopy = { ...localAvailabilities }

      setLocalAvailabilities({ added: [], removed: [] })

      if (enableBlockerEmails) {
        // Get updated availabilities (after removal/additions)
        api
          .get<APIAvailabilityData>('client_area/meetings/availabilities')
          .then((updatedAvailabilities) => {
            // Send emails for availabilities that match any availabilities changed in this submission
            const changedAvailabilities = updatedAvailabilities.data.data.filter((av) => {
              const localAvailability = localAvailabilitiesCopy.added.find((a) =>
                dayjs(a.start_at).isBetween(
                  dayjs(av.attributes.start_at),
                  dayjs(av.attributes.end_at),
                  'day',
                  '[]'
                )
              )

              return localAvailability !== undefined
            })

            if (changedAvailabilities?.length) {
              // Send email for calendar blocks for new availabilities
              sendBlockerEmail.mutateAsync(changedAvailabilities.map((av) => av.id))
            }
          })
      }
    } catch (error) {
      addToast('Houve um erro ao atualizar agenda', { appearance: 'error' })

      Sentry.captureException(error)
    } finally {
      setIsSubmitLoading(false)
    }
  }, [
    localAvailabilities,
    queryClient,
    toggleSubmitDialog,
    addToast,
    enableBlockerEmails,
    addAvailability,
    removeAvailability,
    sendBlockerEmail
  ])

  // 7am
  const startTime = dayjs(date || new Date())
    .startOf('day')
    .add(7, 'hour')

  // 6pm
  const endTime = dayjs(date || new Date())
    .endOf('day')
    .add(-6, 'hour')

  const hasUnsavedChanges = !!(
    localAvailabilities.added.length || localAvailabilities.removed.length
  )

  useUnsavedChanges(hasUnsavedChanges)

  // 11 periods for moning and 11 more for afternoon
  const periods = Array.from(Array(11).keys())

  return {
    hasUnsavedChanges,
    startTime,
    endTime,
    availabilities,
    periods,
    isLoading: isInitialLoading,
    date,
    setDate,
    openAccordion,
    setOpenAccordion,
    isSubmitLoading,
    submitDialog,
    toggleSubmitDialog,
    submitAvailabilities,
    localAvailabilities,
    setLocalAvailabilities,
    sendBlockerEmail
  }
}
