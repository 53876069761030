import { CalendarMonth } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'

import { YuToastProvider, useToggle } from 'yu-open-lib'

import { useCurrentUser } from '@/hooks/auth'

import UserAvailabilitiesDialog from '../availabilities/UserAvailabilitiesDialog'

const YuMatchScheduleButtonNavBar: React.FC = () => {
  const [open, toggleOpen] = useToggle(false)
  const { user } = useCurrentUser()
  return (
    <Box>
      <Tooltip title="Minha agenda match">
        <IconButton onClick={toggleOpen}>
          <CalendarMonth sx={{ color: 'primary.contrastText' }} />
        </IconButton>
      </Tooltip>
      <YuToastProvider>
        <UserAvailabilitiesDialog
          open={open}
          onClose={toggleOpen}
          userId={user?.id as string}
          personName={user?.name as string}
        />
      </YuToastProvider>
    </Box>
  )
}

export default YuMatchScheduleButtonNavBar
