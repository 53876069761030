/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import './index.scss'

type Props = {
  boundaryError?: {
    name: string
    message: string
    stack?: string
  }
}

const Error500: React.FC<Props> = ({ boundaryError }) => {
  const queryClient = useQueryClient()
  const ErrorOverlay = customElements.get('vite-error-overlay')

  useEffect(() => {
    queryClient.cancelQueries()
    localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE')

    if (import.meta.env.DEV && boundaryError && ErrorOverlay) {
      // Render error stack trace if present and in development environment
      // https://github.com/vitejs/vite/issues/2076
      // eslint-disable-next-line no-console
      console.error(boundaryError)
      const overlay = new ErrorOverlay(boundaryError)

      document.body.appendChild(overlay)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundaryError, ErrorOverlay])

  // Production error message
  return (
    <>
      <div className="message">
        <h1>We all make mistakes... </h1>
        <h2>And YURI does too. </h2>
        <p>
          As things seem a bit unstable, why don&apos;t you{' '}
          <span className="gradient-underline">
            <a href="http://yuri.jornadayu.com">return to safety</a>
          </span>
          ?
        </p>
      </div>

      <div className="container">
        <div className="error404page">
          <div className="newcharacter404">
            <div className="chair404"></div>
            <div className="leftshoe404"></div>
            <div className="rightshoe404"></div>
            <div className="legs404"></div>
            <div className="torso404">
              <div className="body404"></div>
              <div className="leftarm404"></div>
              <div className="rightarm404"></div>
              <div className="head404">
                <div className="eyes404"></div>
              </div>
            </div>
            <div className="laptop404"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Error500
