import styled from '@emotion/styled'

import { Skeleton, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { ErrorAlert } from 'yu-open-lib'

import { useCurrentUser } from '@/hooks/auth'

import SupportTable from './table/SupportTable'

const MainContainer = styled.div`
  background: white;
  box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.2);
  padding: 15px;
  min-height: 600px;
  a {
    text-decoration: none;
  }
`

const HelpIndex: React.FC = () => {
  const { user, responsibleCompany } = useCurrentUser()

  if (!user) {
    return <Skeleton height={500} />
  } else if (!responsibleCompany) {
    return (
      <ErrorAlert>
        Parece que seu usuário, da marca {user?.brand_name}, não tem acesso a essa tela. Caso
        acredite que isso seja um erro contate a equipe de Tecnologia da YU
      </ErrorAlert>
    )
  }
  return (
    <MainContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left'
        }}
      >
        <Typography variant="h3">Suporte</Typography>
      </Box>
      {<SupportTable />}
    </MainContainer>
  )
}

export default HelpIndex
