import { JobWarningKey } from '@/hooks/jobsForm'
import { BaseAPISerializer, DataAPISerializer } from '@/types/api'
import { ApiBrandsData, BrandAttributes } from '@/types/brands'
import { EvaluationStep } from '@/types/evaluation_step'

import { SelectOption } from './SelectOption'
import { EvaluationCriterionAttributes } from './evaluation_criterion'
import { JobApplicationStatus } from './job_applications'
import { JobPositionStatus } from './job_position'
import { LogEntry } from './log_entries'
import { RejectionType } from './rejection'
import { ApiTribesData } from './tribes'
import { DataAPIYuriUserData } from './users'

export type ResponsibleCompany = 'yu' | 'plonge' | 'yu_and_plonge' | 'thyme'

export const jobPortugueseStatuses: Record<JobStatus, string> = {
  open_working: 'Aberto: Trabalhando',
  open_no_action: 'Aberto: Sem ação',
  closed_placed: 'Fechado: Placed',
  closed_canceled: 'Fechado: Cancelado',
  closed_internal_indication: 'Fechado: Indicação interna',
  on_hold: 'Congelado',
  in_construction: 'Em Construção',
  pending_kickoff: 'Pendente kickoff',
  open_mentoring: 'Aberto: Mentoria'
}

export interface Brand {
  id: number
  name: string
  created_at: Date
  updated_at: Date
  lead_origin?: any
  article: string
  client: boolean
  offlimits_until?: Date
  aliases: string[]
  offlimits_type: string
  client_type: string
  call_to_positions: string
  confidential: boolean
  slack_channel_name?: string
  slack_channel_id?: string
}

export interface FilterOptions {
  users: DataAPIYuriUserData
  tribes: ApiTribesData
  brands: ApiBrandsData
}

export type OutliersProcessing = 'processed' | 'failure' | 'processing' | 'not_processed' | 'queued'

export type LiteJobAttributes = Pick<
  JobAttributes,
  'id' | 'title' | 'status' | 'created_at' | 'published'
> & {
  slug: string
  brand_id: number
  brand_name: string
  total_positions: number
  last_sent_to_client_at?: Date
  last_placed_at?: Date
  open_at?: Date
  next_submission_on?: Date
  project_type?: JobProjectType
  processing_outliers?: OutliersProcessing
  outliers_processed_at?: Date
  internal_notes: string
  validated_job_post?: boolean
  brand_avatar_url?: string
}

export interface Description {
  id: number
  name: string
  body: string
  record_type: string
  record_id: number
  created_at: Date
  updated_at: Date
}

export type UserRole = 'team' | 'admin' | 'candidate' | 'bot' | 'partner' | 'client'

export interface MemberAttributes {
  id: number | string
  role: null
  user: {
    id: string
    type: 'user'
    attributes: {
      id: number
      name: string
      role: UserRole
      avatar: string
      email: string
    }
  }
  name_and_role: string
  is_manager: boolean
  is_client_admin?: boolean
  is_team_leader: boolean
  is_consultant: boolean
  is_analyst: boolean
  is_hr: boolean
  has_job_access: boolean
  free_slots_count?: number
  primary_phone_number?: string
}

export type ClientInfoType = {
  email: string
  name?: string
  phone?: string
  is_hr?: boolean
  is_manager?: boolean
  has_job_access?: boolean
  is_consultant?: boolean
  is_analyst?: boolean
  is_team_leader?: boolean
  is_client_admin?: boolean
  id?: number | string
}

export interface Tribe {
  id: number
  created_at: Date
  updated_at: Date
  name: string
  call_to_positions: string
  published: boolean
}

export interface User {
  id: string
  type: string
  attributes: LiteUserAttributes
}

export interface Tag {
  id: number
  name: string
  created_at: Date
  updated_at: Date
  taggings_count: number
  color: string
}

export interface RejectionTemplate {
  id: number | string
  job_id?: number
  brand_id?: number
  reason: string
  feedback_text: string
  created_at?: Date
  updated_at?: Date
  rejection_type: RejectionType
}

export interface LiteUserAttributes {
  id: number
  name: string
  role: string
}

export interface UserAttributes extends LiteUserAttributes {
  id: number
  email: string
  created_at: Date
  updated_at: Date
  name: string
  brand: BrandAttributes
  brand_name?: string
  role: string
  calendly_url: string
  whatsapp_engagement_limit?: number
  monthly_telegram_messages_sent: number
  avatar?: string
  primary_phone_number?: string
  is_client_admin?: boolean
}

export type JobStatus =
  | 'in_construction'
  | 'pending_kickoff'
  | 'open_working'
  | 'open_no_action'
  | 'open_mentoring'
  | 'on_hold'
  | 'closed_placed'
  | 'closed_canceled'
  | 'closed_internal_indication'

export const jobStatusOptions: SelectOption<JobStatus>[] = [
  { value: 'open_working', label: 'Aberto: Trabalhando' },
  { value: 'open_no_action', label: 'Aberto: Sem ação' },
  { value: 'pending_kickoff', label: 'Pendente Kickoff' },
  { value: 'on_hold', label: 'Congelado' },
  { value: 'open_mentoring', label: 'Em mentoria' },
  { value: 'in_construction', label: 'Em Construção' },
  { value: 'closed_placed', label: 'Fechado: Placed' },
  { value: 'closed_canceled', label: 'Fechado: Cancelado' }
]

export interface EmailTemplateAttributes {
  name: string
  sendgrid_id: string
  created_at: Date
  updated_at: Date
}

export type CriticalityStatus = 'red' | 'yellow' | 'green'

export interface JobDocument {
  id: number | string
  name: string
  url: string
}

export interface JobThumbnail {
  name: string
  url: string
}

export type JobWorkModel =
  | 'Híbrido (frequência mínima obrigatória)'
  | 'Full Remoto'
  | 'Full Presencial'
  | 'Flexível (decisão do profissional)'

export type JobHiringModel = 'CLT' | 'PJ' | 'Estágio' | 'Sócio' | 'Flex (PJ+CLT)'

export type JobProjectType =
  | 'single'
  | 'program'
  | 'market_research'
  | 'assessment'
  | 'talent_pool'
  | 'naikan'

export const jobProjectTypeOptions: SelectOption<JobProjectType>[] = [
  { value: 'single', label: 'Pontual' },
  { value: 'program', label: 'Volume' },
  { value: 'market_research', label: 'Pesquisa de mercado' },
  { value: 'naikan', label: 'Naikan' },
  { value: 'assessment', label: 'Assessment' },
  { value: 'talent_pool', label: 'Banco de talentos' }
]

export type JobCurrency =
  | 'USD'
  | 'BRL'
  | 'EUR'
  | 'GBP'
  | 'CAD'
  | 'HKD'
  | 'JPY'
  | 'CHF'
  | 'SGD'
  | 'CNY'

export const jobCurrencySimbol = {
  USD: '$',
  BRL: 'R$',
  EUR: '€',
  GBP: '£',
  CAD: 'CA$',
  HKD: 'HK$',
  JPY: '¥',
  CHF: 'CHF',
  SGD: 'S$',
  CNY: '¥'
}

export const jobCurrencyOptions: SelectOption<JobCurrency>[] = [
  { value: 'BRL', label: 'Real Brasileiro' },
  { value: 'USD', label: 'Dólar Americano' },
  { value: 'CAD', label: 'Dólar Canadense' },
  { value: 'HKD', label: 'Dólar de Hong Kong' },
  { value: 'SGD', label: 'Dólar de Singapura' },
  { value: 'EUR', label: 'Euro' },
  { value: 'CHF', label: 'Franco Suíço' },
  { value: 'JPY', label: 'Iene Japonês' },
  { value: 'GBP', label: 'Libra Esterlina' },
  { value: 'CNY', label: 'Remimbi' }
]

interface EvaluationCriterion extends EvaluationCriterionAttributes {
  id: string | number
}

export type JobFilters = {
  status?: JobStatus
  project_type: (JobProjectType | null)[]
  responsible_company?: ResponsibleCompany
  job_position_status?: JobPositionStatus
}

export interface JobAttributes {
  id: string
  use_new_chatbot: boolean
  slack_channel_id?: string
  is_client_match: boolean
  internal_sensitive_job: boolean
  title: string
  appearing_in_candidates_area?: boolean
  show_new_candidates_on_slack: boolean
  tribe_id: number
  project_id: number
  internal_notes: string
  created_at: Date
  updated_at: Date
  uuid: string
  cloned_from?: number
  repair_from_job_id?: number
  redirect_job_posts: boolean
  recovery_stand_by_candidates: boolean
  validated_job_post?: boolean
  slug: string
  outlier?: number
  ask_for_other_brands: boolean
  status: JobStatus
  briefing_on: string
  lgpd_compliant: boolean
  next_submission_on: string
  final_submission_on: string
  target_salary_min: string
  target_salary_max: string
  variable_compensation_multiplier: number
  variable_per_month: number
  currency: JobCurrency
  default_meeting_duration: number
  ready_to_publish: boolean
  confidential: boolean
  brand_id: number
  alignment_id: number
  nps_candidate_follow_up_template: string
  nps_client_follow_up_template: string
  call_to_action: string
  published: boolean
  youtube_video_url: string
  manual_follow_up_message: string
  translated_status: string
  placed_candidates_count: number
  active: boolean
  tribe_picture_url: string
  people_contacted: number
  total_positions: number
  positions_count: number
  financial_id: string
  activate_telegram_bot: boolean
  proactive: boolean
  repair: boolean
  reference_check_questionnaire: boolean
  closed: boolean
  candidate_salary_mean: string
  safe_public_title: string
  max_chatbot_score: number
  public_title: string
  subtitle?: string
  active_telegram_bot: boolean
  encode_videos: boolean
  mandatory_interview_rating: boolean
  custom_gdpr_url?: string
  custom_gdpr_message?: string
  signoff_email_template_id?: string
  signoff_no_feedback_email_template_id?: string
  meeting_interviewer_email_template_id?: string
  meeting_interviewee_email_template_id?: string
  meeting_interview_reminder_email_template_id?: string
  post_application_candidate_email_template_id?: string
  candidate_nps_email_template_id?: string
  sourcing_needed: boolean
  days_for_signoff_emails: number
  days_for_engagement_followup_emails: number
  closed_positions: number
  open_positions: number
  candidates_sent_to_client: number
  candidates_active_sent_to_client: number
  yu_contact_id?: number
  block_chatbot_pending_for_client?: boolean
  validated_chatbot?: boolean
  show_in_construction: boolean
  change_job_positions_open_date: boolean
  only_open_positions_count: number
  locale: 'pt-BR' | 'en' | 'es'
  journey_language: 'en' | 'pt' | 'es'
  work_model: JobWorkModel
  hiring_model: JobHiringModel
  responsible_company: ResponsibleCompany
  client_match_type: ClientMatchType
  error_messages: string[]
  job_info_report?: Description
  description: Description
  match_description?: Description
  thumbnail: JobThumbnail
  documents: JobDocument[]
  evaluation_criteria?: EvaluationCriterion[]
  chatbot_validation_document?: JobDocument
  yu_members: any
  tribe: Tribe
  tags: Tag[]
  job_tags: any
  job_post_validation_document?: JobDocument
  /**
   * The YU User that candidates will have contact information to comunicate about the selective process for that Job
   */
  yu_contact?: DataAPISerializer<BaseAPISerializer<UserAttributes>>
  evaluation_steps: BaseAPISerializer<EvaluationStep>[]
  members: BaseAPISerializer<MemberAttributes>[]
  client_members: BaseAPISerializer<MemberAttributes>[]
  meta?: JobMeta
  rejectionGroups?: Record<string, RejectionTemplate[]>
  project_type: JobProjectType
  log_entries: BaseAPISerializer<LogEntry>[]
  criticality_status: CriticalityStatus
}

export type JobAttributesNames = keyof JobAttributes

export type ClientMatchType = 'automatic' | 'manual' | 'none'

export interface Origin {
  name: string
  translated: string
}

export interface StatusReportData {
  id: string
  label: string
  value: number
}

export interface CommonRejection {
  job_id: number
  rejection_type: RejectionType
  reason: string
  count: number
  percentage: number
}

export interface PendingReferenceChecks {
  data: any[]
}

export interface JobsError {
  message: string
  field: keyof JobAttributes | JobWarningKey
  type: 'warning' | 'error'
}

export interface JobMeta {
  errors?: Record<keyof JobAttributes, string[]>
  total_applications: number
  active_applications: number
  stand_by_applications: number
  pending_signoff_applications: number
  sign_off_ok_applications: number
  total_refusals_count: number
  next_submission_date: string
  briefing_date: string
  rejection_types: string[]
  tags: Tag[]
  origins: Origin[]
  status_report_data: StatusReportData[]
  status_report_color_scale: string[]
  common_rejections_yuri: CommonRejection[]
  rejection_graph_labels: string[]
  rejection_graph_values: number[]
  pending_reference_checks: PendingReferenceChecks
  active_pending_or_standby_count: number
  sent_to_client_count: number
  status_options: SelectOption<JobStatus>[]
  criticality_status_options: SelectOption<CriticalityStatus>[]
  client_match_type_options: SelectOption<ClientMatchType>[]
  responsible_company_options: SelectOption<ResponsibleCompany>[]
  total_positions: number
  error_meeting_count: number
  client_users_count: number
  to_be_sent_to_client_count?: number
  last_candidate_sent_at?: Date
  sent_to_client_active_count?: number
  work_model_options?: SelectOption[]
  hiring_model_options?: SelectOption[]
}

export type CandidateFeedbackAttributes = {
  uuid: string
  created_at: Date
  updated_at: Date
  status: JobApplicationStatus
  translated_status: string
  avatar: string
  name: string
  evaluation_step: string
}

export type JobFeedbacksInfo = {
  average_temperature: number
  candidates: {
    sent: number
    sent_active: number
    active: number
    pending_sign_off: number
    pending_human_sign_off: number
    pending_automatic_sign_off: number
    standby: number
    forgotten: number
    positions: number
  }
}

export type JobFeedbackType =
  | 'sent'
  | 'sent_active'
  | 'active'
  | 'pending_sign_off'
  | 'pending_human_sign_off'
  | 'pending_automatic_sign_off'
  | 'standby'
  | 'forgotten'
  | 'positions'

export type JobFeedbacksInfoByStep = {
  [key in JobFeedbackType]: number[]
}

export type JobFeedbackCandidateData = DataAPISerializer<
  BaseAPISerializer<CandidateFeedbackAttributes>[]
>

export type JobFeedbacksInfoWithCandidates = {
  average_temperature: number
  candidates: {
    sent: JobFeedbackCandidateData
    sent_active: JobFeedbackCandidateData
    active: JobFeedbackCandidateData
    pending_sign_off: JobFeedbackCandidateData
    pending_human_sign_off: JobFeedbackCandidateData
    pending_automatic_sign_off: JobFeedbackCandidateData
    standby: JobFeedbackCandidateData
    forgotten: JobFeedbackCandidateData
    interview: JobFeedbackCandidateData
    outlier: JobFeedbackCandidateData
  }
}

export type JobAdjustmentRequestStatus = 'pending' | 'answered'

export type JobPostAdjustmentRequest = {
  id: number
  job_id: number | string
  job_uuid: string
  requested_user_name: string
  answered_user_name: string
  requested_user_id: number | string
  answered_user_id: number
  created_at: Date
  updated_at: Date
  answered_at: Date | null
  answered: boolean
  status: JobAdjustmentRequestStatus
  adjustment_type: 'adjustment_request' | 'adjustment_response'
  answer_comment: string
  comment: string
}

export type JobPostAdjustmentRequestsData = DataAPISerializer<
  BaseAPISerializer<JobPostAdjustmentRequest>[]
>

export type JobPostAdjustmentRequestDatum = BaseAPISerializer<JobPostAdjustmentRequest>

export interface JobProcessingOutliersStatus {
  processing_outliers?: OutliersProcessing
  outliers_processed_at: Date
}

export type ApiLiteJobData = DataAPISerializer<BaseAPISerializer<LiteJobAttributes>[]>
export type ApiLiteJobDatum = DataAPISerializer<BaseAPISerializer<LiteJobAttributes>>

export type ApiJobsData = DataAPISerializer<BaseAPISerializer<JobAttributes, JobMeta>[]>
export type ApiJobData = DataAPISerializer<BaseAPISerializer<JobAttributes, JobMeta>>
export type ApiEmailTemplatesData = DataAPISerializer<BaseAPISerializer<EmailTemplateAttributes>[]>

export type AverageDaysStepsDatum = {
  id?: string
  name: string
  count: number
}

export type AverageDaysStepsData = AverageDaysStepsDatum[]

export type APIMemberDatum = DataAPISerializer<BaseAPISerializer<MemberAttributes>>
export type APIMemberData = DataAPISerializer<BaseAPISerializer<MemberAttributes>[]>
