export const TOKEN_KEY = 'yuri_authorization_token'
export const EMAIL_KEY = 'yuri_email'

export const LINKEDIN_REDIRECT_PATH = '/users/auth/linkedin/callback/'
export const LINKEDIN_CLIENT_ID = '775kbbexggycc0'
export const LINKEDIN_OAUTH_SCOPE = 'r_liteprofile+r_emailaddress'

// eslint-disable-next-line
let GITHUB_CLIENT_ID = import.meta.env.VITE_APP_GITHUB_CLIENT_ID_DEV
// eslint-disable-next-line
let GOOGLE_CLIENT_ID = import.meta.env.VITE_APP_GOOGLE_CLIENT_ID_DEV
// eslint-disable-next-line
let MICROSOFT_CLIENT_ID = import.meta.env.VITE_APP_MICROSOFT_CLIENT_ID

if (import.meta.env.PROD) {
  if (window.location.href.match(/.*staging.*/)) {
    GITHUB_CLIENT_ID = import.meta.env.VITE_APP_GITHUB_CLIENT_ID_STAGING
    GOOGLE_CLIENT_ID = import.meta.env.VITE_APP_GOOGLE_CLIENT_ID_STAGING
  } else {
    GITHUB_CLIENT_ID = import.meta.env.VITE_APP_GITHUB_CLIENT_ID_PRODUCTION
    GOOGLE_CLIENT_ID = import.meta.env.VITE_APP_GOOGLE_CLIENT_ID_PRODUCTION
  }
}
export { GITHUB_CLIENT_ID }
export { GOOGLE_CLIENT_ID }
export { MICROSOFT_CLIENT_ID }

export const GITHUB_OAUTH_SCOPE = 'user:email'
export const GITHUB_REDIRECT_PATH = '/users/auth/github/callback/'

export const GOOGLE_OAUTH_SCOPE = 'email profile'
export const GOOGLE_REDIRECT_PATH = '/users/auth/google_oauth2/callback/'

export const MICROSOFT_OAUTH_SCOPE = import.meta.env.VITE_APP_MICROSOFT_OAUTH_SCOPE || ''
export const MICROSOFT_REDIRECT_PATH = '/users/auth/microsoft/callback/'

export const API_BASE_URL = import.meta.env.VITE_APP_API_URL || 'http://localhost:3000/api'
