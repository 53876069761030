import { Link as RouterLink } from 'react-router-dom'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Badge } from '@mui/icons-material'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import {
  AppBar,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Link,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useToggle } from 'yu-open-lib'

import { logout } from '@/api/authenticaton'
import PlongeLogo from '@/assets/images/plonge_white_and_orange.png'
import YuriLogo from '@/assets/images/yuri_white.png'
import YuDrawer from '@/components/shared/YuDrawer'
import YuMobileNavBar from '@/components/shared/YuMobileNavBar'
import YuNavbarSearch from '@/components/shared/YuNavBarSearch'
import { useNavBarItems } from '@/hooks'
import { useCurrentUser } from '@/hooks/auth'
import { ApiPersonData } from '@/types/person'

import NotificationMenuButton from './NotificationMenuButton'
import YuMatchScheduleButtonNavBar from './YuMatchScheduleButtonNavBar'

let colorEnvironment = '#434242'

if (window.location.hostname.startsWith('localhost')) {
  colorEnvironment = '#66AB3E'
} else if (window.location.hostname.startsWith('staging')) {
  colorEnvironment = '#DE4573'
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(-1.5)
  },
  appBar: {
    background: colorEnvironment,
    height: 60
  },
  title: {
    flexGrow: 1
  },
  person: {
    marginLeft: theme.spacing(3),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& hr': {
      margin: theme.spacing(0, 2)
    },
    '& $h6': {
      fontFamily: 'Integral CF Bold'
    }
  },
  logoYuri: {
    marginRight: theme.spacing(-3),
    marginLeft: theme.spacing(-1)
  },
  logoYuriMobile: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.3)
  },
  logoPlonge: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-2)
  },
  toolbarButton: {
    margin: theme.spacing(0, 0, 0, 3),
    fontSize: 20
  },
  externalAppBar: {
    alignItems: 'center'
  },
  toolBar: {
    minHeight: 20,
    maxHeight: 60
  }
}))

type Props = {
  person?: ApiPersonData
  toggleJobApplicationsDialog?: () => void
  jobApplicationsCount?: number
  jobApplicationLabel?: string
  jobApplicationPath?: string
}

const YuAppBar: React.FC<Props> = ({
  person,
  toggleJobApplicationsDialog,
  jobApplicationsCount,
  jobApplicationLabel,
  jobApplicationPath
}) => {
  const classes = useStyles({ person })
  // TODO: Replace with isWide from useViewport from yu-open-lib after
  // https://bitbucket.org/sal-jornadayu/yu-open-lib/pull-requests/59/v174-fix-iswide is merged.
  // const theme = useTheme()
  // const isWide = useMediaQuery(theme.breakpoints.up('xl'))
  const { userCanManage, isUserPlonge, refetch } = useCurrentUser()
  const queryClient = useQueryClient()

  const [leftDrawer, toggleLeftDrawer] = useToggle(false)
  // TODO: 'permanent' variant hides content in some pages
  // https://material-ui.com/components/drawers/#persistent-drawer
  // should push them instead
  // const isDrawerOpen = leftDrawer || isWide
  const isDrawerOpen = leftDrawer

  const { leftItems, rightItems } = useNavBarItems()

  const logoutMutation = useMutation(logout, {
    onSettled() {
      queryClient.invalidateQueries(['user'])
      refetch()
    }
  })
  if (!userCanManage) {
    return (
      <AppBar className={`${classes.appBar} ${classes.externalAppBar}`}>
        <Toolbar className={`${classes.toolBar}`}>
          <Typography variant="h2" className={classes.title}>
            <IconButton
              className={classes.logoYuri}
              color="inherit"
              href="https://jornadayu.com"
              size="large"
            >
              <img src={YuriLogo} width="32" height="42" />
            </IconButton>
          </Typography>
        </Toolbar>

        <div className="gradient-line" />
      </AppBar>
    )
  }

  return (
    <>
      <YuMobileNavBar classes={classes} toggleLeftDrawer={toggleLeftDrawer} />
      <YuDrawer open={isDrawerOpen} handleClose={toggleLeftDrawer} />

      <Hidden mdDown>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Typography variant="h2" className={classes.title}>
              <IconButton
                className={classes.logoYuri}
                color="inherit"
                onClick={toggleLeftDrawer}
                size="large"
                sx={{ mb: 1.2 }}
                aria-label="open-navigation"
              >
                <img src={YuriLogo} width="32" height="42" />
                {isUserPlonge && <img src={PlongeLogo} width="36" height="36" />}
              </IconButton>

              {leftItems.map((button) => (
                <Button
                  key={button.path}
                  className={classes.toolbarButton}
                  size="large"
                  color="inherit"
                  href={button.path}
                  startIcon={button.icon}
                >
                  {button.text}
                </Button>
              ))}
            </Typography>

            {!!person && (
              <>
                <Grid className={classes.person}>
                  <Typography variant="h6">{person.attributes.name}</Typography>
                  {!!jobApplicationsCount && (
                    <>
                      <Divider orientation="vertical" flexItem />
                      <Tooltip title="Ir para a vaga">
                        <Link
                          component={RouterLink}
                          to={`${jobApplicationPath?.split('?highlight')[0]}#candidates`}
                          variant="h6"
                          underline="hover"
                          sx={{
                            color: 'primary.contrastText',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {jobApplicationLabel}
                        </Link>
                      </Tooltip>

                      {jobApplicationsCount > 1 && (
                        <IconButton
                          onClick={toggleJobApplicationsDialog}
                          size="large"
                          sx={{ color: 'primary.contrastText' }}
                        >
                          +{jobApplicationsCount - 1}
                        </IconButton>
                      )}
                    </>
                  )}
                </Grid>
                <Tooltip title="Ver candidato na vaga">
                  <IconButton sx={{ color: 'primary.contrastText' }}>
                    <Link
                      component={RouterLink}
                      to={jobApplicationPath || '#'}
                      variant="h6"
                      underline="hover"
                      sx={{ color: 'primary.contrastText', display: 'flex' }}
                    >
                      <Badge />
                    </Link>
                  </IconButton>
                </Tooltip>
              </>
            )}

            <YuNavbarSearch />

            <NotificationMenuButton />

            <YuMatchScheduleButtonNavBar />

            {rightItems.map((button) => (
              <Button
                key={button.path}
                className={classes.toolbarButton}
                size="large"
                color="inherit"
                href={button.path}
                startIcon={button.icon}
                {...button}
              >
                {button.text}
              </Button>
            ))}

            <Tooltip title="Sair">
              <IconButton
                type="submit"
                color="inherit"
                onClick={() => logoutMutation.mutate()}
                size="large"
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>

          <div className="gradient-line" />
        </AppBar>
      </Hidden>
    </>
  )
}

export default YuAppBar
