import { Box, Card, CardActions, styled, experimental_sx as sx } from '@mui/material'

export const BottomCardActionsSeparator = styled(Box)(
  sx({
    flexGrow: 1
  })
)

export const FlexColumnCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column'
})

/**
 * @example
 * <FlexColumnCard sx={{ display: 'flex', flexDirection: 'column' }}>\
 *   <CardHeader title="..." />
 *
 *   <CardContent>
 *     ...
 *   </CardContent>
 *
 *   <BottomCardActionsSeparator />
 *
 *   <BottomCardActions>
 *     ...
 *   </BottomCardActions>
 * </FlexColumnCard>
 */
export const BottomCardActions = styled(CardActions)(
  sx({
    justifyContent: 'flex-end',
    bottom: 0,
    zIndex: 1,
    bgcolor: 'background.paper',
    borderTop: '1px solid',
    borderColor: 'divider',
    width: '100%',
    left: {
      sm: 'initial',
      xs: 0
    },
    position: {
      sm: 'sticky',
      xs: 'fixed'
    }
  })
)
