import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material'
import { Skeleton } from '@mui/material'

import { jobPortugueseStatuses } from '@/types/jobs'
import { JobSearch, PersonSearch } from '@/types/search'

import PersonAvatar from '../projects/PersonAvatar'

type Props = {
  isLoading: boolean
  peopleToSuggest?: PersonSearch[]
  jobsToSuggest?: JobSearch[]
  searchQuery: string
  setIsMouseHoveringDropdown: React.Dispatch<React.SetStateAction<boolean>>
  setShowResultsPreview: React.Dispatch<React.SetStateAction<boolean>>
  searchWasBlurred: boolean
}

const SearchBarResults: React.FC<Props> = ({
  peopleToSuggest,
  jobsToSuggest,
  searchQuery,
  setIsMouseHoveringDropdown,
  setShowResultsPreview,
  searchWasBlurred,
  isLoading
}) => {
  const textWithEnsuredLength = (text: string) => {
    if (!text) return

    const maximumSizeToRender = 50
    if (text.length > maximumSizeToRender) {
      return text.slice(0, maximumSizeToRender - 2) + '...'
    }

    return text
  }

  function handleMouseEnter() {
    setIsMouseHoveringDropdown(true)
  }

  function handleMouseLeave() {
    if (searchWasBlurred) {
      setIsMouseHoveringDropdown(false)
      setShowResultsPreview(false)
    }
  }

  if (isLoading) {
    return (
      <Card
        sx={{
          zIndex: 99999999,
          backgroundColor: 'white',
          position: 'fixed',
          top: '11%',
          right: '3%',
          width: 'auto',
          maxHeight: 'auto',
          minWidth: '30ch',
          marginTop: '-1.5%'
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CardContent>
          <List disablePadding>
            {[...Array(6).keys()].map((i) => (
              <ListItem key={`search-preview-skeleton-${i}`}>
                <ListItemAvatar>
                  <Avatar />
                </ListItemAvatar>

                <ListItemText
                  primary={<Skeleton width={300} />}
                  secondary={<Skeleton width="75%" />}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    )
  }

  const cutLastJob = (jobsToSuggest?.length || 0) > 3

  return (
    <Card
      sx={{
        zIndex: 99999999,
        backgroundColor: 'white',
        position: 'fixed',
        top: '11%',
        right: '3%',
        width: 'auto',
        maxHeight: 'auto',
        minWidth: '30ch',
        marginTop: '-1.5%'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardContent>
        <List disablePadding>
          {!!peopleToSuggest?.length && (
            <>
              <b>Candidatos</b>
              <Divider light />
            </>
          )}

          {peopleToSuggest?.map((person) => (
            <ListItem
              key={`item-${person.attributes.name}-${person.id}`}
              button
              component="a"
              href={`/people/${person.id}`}
            >
              <ListItemAvatar>
                <PersonAvatar personId={person.id} name={person.attributes.name} />
              </ListItemAvatar>
              <ListItemText
                primary={textWithEnsuredLength(person.attributes.name)}
                secondary={textWithEnsuredLength(person.attributes.email)}
              />
            </ListItem>
          ))}

          <Divider light />

          <Button fullWidth color="primary" sx={{ mt: 2 }} href={`/search/people?q=${searchQuery}`}>
            Ver essa pesquisa na nova busca
          </Button>

          {!!jobsToSuggest?.length && (
            <>
              <b style={{ marginTop: 24 }}>Vagas</b>
              <Divider light />
            </>
          )}

          {/* Cut last extra job, if it exists */}
          {jobsToSuggest
            ?.slice(0, cutLastJob ? jobsToSuggest.length - 1 : jobsToSuggest.length)
            .map((job) => (
              <ListItem
                key={`item-${job.attributes.title}-${job.id}`}
                button
                component="a"
                href={`/jobs/${job.attributes.slug}`}
              >
                <ListItemAvatar>
                  <Avatar src={job.attributes.brand_avatar_url} />
                </ListItemAvatar>
                <ListItemText
                  primary={job.attributes.title}
                  secondary={
                    <>
                      <div>{job.attributes.brand_name}</div>

                      <div>{jobPortugueseStatuses[job.attributes.status]}</div>
                    </>
                  }
                />
              </ListItem>
            ))}

          {cutLastJob && (
            <ListItem button component="a" href={`/search_for_jobs?query=${searchQuery}`}>
              <ListItemText
                primary="Ver mais vagas"
                sx={{
                  textAlign: 'center'
                }}
              />
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  )
}

export default SearchBarResults
