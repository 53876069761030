import { useMemo, useState } from 'react'

import { Controller } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  Autocomplete,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material'

import { FileUploadInput } from 'yu-open-lib'

import api from '@/api/apiToken'
import ReleaseSearch from '@/components/shared/yuri_releases/ReleaseSearch'
import { useCRUD } from '@/hooks/api'
import { DataAPISerializer } from '@/types/api'
import {
  APISupportTicketData,
  OptionSupportTabble,
  SupportTicket,
  SupportTicketAttributes
} from '@/types/support_ticket'
import { YuriUserAttributes } from '@/types/users'

type BaseProps = {
  open: boolean
  onClose: () => void
}

type EditProps = {
  edit: true
  myModel: SupportTicket
  id: string | number
  user: YuriUserAttributes
}

type CreateProps = {
  edit?: never
  myModel?: never
  id?: never
  user: YuriUserAttributes | undefined
}

const SupportDialog: React.FC<BaseProps & (EditProps | CreateProps)> = ({
  open,
  onClose,
  edit,
  myModel,
  id,
  user
}) => {
  const cacheKey = ['support_ticket']
  const queryClient = useQueryClient()
  const { data: helpTypeOptions } = useQuery<OptionSupportTabble[]>([
    'support_ticket',
    'help_type_options'
  ])
  const { data: urgencyTypeOptions } = useQuery<OptionSupportTabble[]>([
    'support_ticket',
    'urgency_type_options'
  ])

  const { data: tickets } = useQuery<APISupportTicketData>(['support_ticket'], { enabled: open })
  const [attachments, setAttachments] = useState<File[]>([])

  const ticketOptions = useMemo(() => {
    return tickets?.data.map((ticket) => ({
      label: `${ticket.id} - ${
        ticket.attributes.title ? ticket.attributes.title : 'Ticket sem Título'
      }`,
      id: ticket.id
    }))
  }, [tickets])

  const {
    createMutation,
    updateMutation,
    form: {
      control,
      handleSubmit,
      formState: { errors },
      trigger
    }
  } = useCRUD('support_ticket', {
    cacheKey: edit ? ['support_ticket', myModel?.id] : cacheKey,
    id: edit ? id : undefined,
    errorMessage: 'Houve um erro ao salvar o seu pedido de ajuda',
    onSuccess() {
      addToast('Pedido registrado com sucesso', { appearance: 'success', autoDismiss: true })
      onClose()
    },
    record: myModel
  })

  // se tirar o trigger ele não consegue validar os campos required do form
  trigger('title')

  const { addToast } = useToasts()

  const submit = (values: Partial<SupportTicketAttributes>) => {
    if (edit) {
      updateMutation.mutate(values)
    } else {
      createMutation.mutate(
        { ...values, user_id: user ? user.id : undefined },
        {
          onSuccess: (data) => {
            const formData = new FormData()
            const { id: ticketId } = (data as DataAPISerializer<SupportTicket>).data
            attachments.forEach((file) => {
              formData.append('attachments[]', file)
            })
            formData.append('send_slack_message', 'true')
            uploadAttachment.mutate({ formData, ticketId })
          }
        }
      )
    }
  }

  const uploadAttachment = useMutation(
    ({ formData, ticketId }: { formData: FormData; ticketId: string }) =>
      api.patch(`support_ticket/${ticketId}`, formData),
    {
      onSuccess() {
        queryClient.invalidateQueries(cacheKey)
        addToast('Imagem adicionada com sucesso')
        setAttachments([])
      },
      onError() {
        addToast('Houve um erro ao adicionar essa imagem, tente novamente mais tarde', {
          appearance: 'error'
        })
        setAttachments([])
      }
    }
  )

  const onUploadImage = async (file: File) => {
    await setAttachments([...attachments, file])
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setAttachments([])
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <Stack direction="row" gap={1}>
          <ReleaseSearch />

          <Card variant="outlined" sx={{ flex: 1, p: 1 }}>
            <Grid container sx={{ gap: 1 }}>
              <Typography variant="h5">
                {edit ? 'Editando' : 'Criando'} uma solicitação de suporte
              </Typography>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  rules={{ required: { value: true, message: 'Título não pode ficar em branco' } }}
                  name="title"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      error={!!errors.title}
                      label="Título"
                      variant="outlined"
                      helperText={errors.title ? errors.title.message : ''}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="help_type"
                  defaultValue={'problem'}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label="Tipo de suporte"
                      variant="outlined"
                      select
                      sx={{ width: '100%' }}
                      error={!!errors.help_type}
                    >
                      {helpTypeOptions?.map((opt) => (
                        <MenuItem value={opt ? opt.value : ''} key={opt.value}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  rules={{
                    required: { value: true, message: 'Nível de urgência não pode ficar em branco' }
                  }}
                  name="urgency_type"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      required
                      fullWidth
                      error={!!errors.urgency_type}
                      select
                      sx={{ width: '100%' }}
                      helperText={errors.urgency_type ? errors.urgency_type.message : ''}
                    >
                      {urgencyTypeOptions?.map((opt) => (
                        <MenuItem value={opt.value} key={opt.value}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="related_ticket_id"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Autocomplete
                        options={ticketOptions || []}
                        value={{
                          label: value,
                          id: value
                        }}
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue?.id)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Esse problema já foi reportado antes?"
                            onChange={({ target }) => onChange(target.id)}
                          />
                        )}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  rules={{
                    required: { value: true, message: 'Descrição não pode ficar em branco' }
                  }}
                  name="description"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label="Descrição"
                      error={!!errors.description}
                      variant="outlined"
                      helperText={errors.description ? errors.description.message : ''}
                      multiline
                      rows={2}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              {attachments.length > 0 && (
                <Grid container spacing={1}>
                  {attachments.map((file, index) => (
                    <Grid item xs={12} key={index}>
                      <Typography variant="body1" color="success.dark" ml={1}>
                        {file.name}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              )}
              <Grid item xs={12}>
                <FileUploadInput
                  field="attachment"
                  label="Anexar Arquivo"
                  accept="*"
                  onSubmit={onUploadImage}
                  autoSave={true}
                />
              </Grid>
            </Grid>
          </Card>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            onClose()
            setAttachments([])
          }}
        >
          Cancelar
        </Button>

        <Button color="secondary" onClick={handleSubmit(submit)}>
          Enviar pedido
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SupportDialog
