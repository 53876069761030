import styled from '@emotion/styled'

import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { GridToolbarContainer } from '@mui/x-data-grid'

import { SupportFilter } from '@/types/support_ticket'

import SupportFilters from './SupportFilters'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

type Props = {
  filters: Partial<SupportFilter>
  setFilters: (filters: Partial<SupportFilter>) => void
}

const SupportTableToolbar: React.FC<Props> = ({ filters, setFilters }) => {
  return (
    <GridToolbarContainer style={{ width: '100%' }}>
      <MainContainer>
        <Box>
          <SupportFilters filters={filters} setFilters={setFilters} />
        </Box>
        <Box mt={2} />
        <Box width="100%" display="flex" justifyContent="flex-end" paddingRight={2}>
          <Typography variant="body2">Clique 2x vezes para editar a tabela</Typography>
        </Box>
      </MainContainer>
    </GridToolbarContainer>
  )
}

export default SupportTableToolbar
