import { DeepPartial, Path, UseFormProps, useForm } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import {
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'

import api from '@/api/apiToken'
import { BaseAPISerializer, DataAPISerializer, MutationError } from '@/types/api'

/**
 * ```tsx
 * import { useMutation } from '@tanstack/react-query'
 *
 * interface StuffAttributes {
 *   fieldA: string
 *   fieldB: number
 * }
 *
 * type Props = {
 *   id: number
 * }
 *
 * const MyComponent: React.FC<Props> = ({ id }) => {
 *   const someMutation = useMutation(
 *     (params: Partial<StuffAttributes>) => updateObject(`stuffs/${id}`, params),
 *     {
 *       onSuccess(data) {
 *          console.log(data.data.attributes.fieldA, data.data.attributes.fieldB)
 *       }
 *     }
 *   )
 * }
 * ```
 */
export const updateObject = async <TAttributes>(
  url: string,
  params: Partial<TAttributes>
): Promise<DataAPISerializer<BaseAPISerializer<TAttributes>>> => {
  const { data } = await api.patch<DataAPISerializer<BaseAPISerializer<TAttributes>>>(url, params)

  return data
}

export const destroyObject = async (url: string): Promise<void> => {
  await api.delete(url)
}

/**
 * ```tsx
 * import { useMutation } from '@tanstack/react-query'
 *
 * interface StuffAttributes {
 *   fieldA: string
 *   fieldB: number
 * }
 *
 * type Props = {
 *   id: number
 * }
 *
 * const MyComponent: React.FC<Props> = ({ id }) => {
 *   const someMutation = useMutation(
 *     (params: Partial<StuffAttributes>) => createObject(`stuffs/${id}`, params),
 *     {
 *       onSuccess(data) {
 *          console.log(data.data.attributes.fieldA, data.data.attributes.fieldB)
 *       }
 *     }
 *   )
 * }
 * ```
 */
export const createObject = async <TAttributes>(
  url: string,
  params: Partial<TAttributes>
): Promise<DataAPISerializer<BaseAPISerializer<TAttributes>>> => {
  const { data } = await api.post<DataAPISerializer<BaseAPISerializer<TAttributes>>>(url, params)

  return data
}

export type UseCRUDMutationOptions<TAttributes> = UseMutationOptions<
  DataAPISerializer<BaseAPISerializer<TAttributes>>,
  MutationError<TAttributes>,
  Partial<TAttributes>,
  () => void
>

export type UseCRUDDestroyMutationOptions = UseMutationOptions<
  void,
  MutationError,
  void,
  () => void
>

export type UseCRUDMutationResultOptions<TAttributes> = UseMutationResult<
  DataAPISerializer<BaseAPISerializer<TAttributes>> | BaseAPISerializer<TAttributes>,
  MutationError<TAttributes>,
  Partial<TAttributes>,
  () => void
>

export type UseCRUDOptions<TAttributes extends Record<string, unknown>> = {
  /**
   * ID for the specified object to be updated, if present
   */
  id?: number | string
  /**
   * Cache key used for useQuery, by default will be invalidated on creation and updates
   */
  cacheKey?: QueryKey

  create?: UseCRUDMutationOptions<TAttributes>
  update?: UseCRUDMutationOptions<TAttributes>
  destroy?: UseCRUDDestroyMutationOptions
  /**
   * Error message that will be shown in a Error toast on errors, if error request
   * does not return an error message to be used
   */
  errorMessage?: string
  /**
   * Generic Success message to be shown by default on the `onSuccess` callback, if a
   * `onSuccess` callback was not passed explicitly
   */
  successMessage?: string
  /**
   * Callback to be called in every `onSuccess` callback after the default `onSuccess` callback,
   * it will be overrided for any methods that had an `onSuccess` callback passed explicitly
   */
  onSuccess?: (data?: DataAPISerializer<BaseAPISerializer<TAttributes>>) => void
  /**
   * Callback to be called in every `onError` callback after the default `onError` callback,
   */
  onError?: (error?: MutationError<TAttributes>) => void
  /**
   * Record to be updated/deleted, if present
   *
   * Also used to set default values for react-hook-form `form` return and callbacks
   */
  record?: BaseAPISerializer<Partial<TAttributes>, any>
  /**
   * Extra options to be passed to react-hook-form `useForm`
   *
   * Read more at: https://react-hook-form.com/api/useform/
   */
  formOptions?: UseFormProps<Partial<TAttributes>>
}

export type UseCRUDReturn<TAttributes> = {
  createMutation: UseCRUDMutationResultOptions<TAttributes>
  updateMutation: UseCRUDMutationResultOptions<TAttributes>
  destroyMutation: UseMutationResult<void, MutationError<TAttributes>, void, () => void>
  /** `true` if *any* of the CRUD mutations are loading */
  isLoading: boolean
  /** https://react-hook-form.com/api/useform */
  form: UseFormReturn<Partial<TAttributes>>
}

const defaultUseCRUDOptions = {
  id: undefined,
  cacheKey: undefined,
  create: {},
  update: {},
  destroy: {},
  errorMessage: undefined,
  successMessage: undefined,
  onSuccess: undefined,
  onError: undefined,
  record: undefined,
  formOptions: {}
}

export const useCRUD = <TAttributes extends Record<string, any>>(
  /**
   * API route for the index of the object without the 'api' prefix
   *
   * Ex.: 'jobs'
   */
  indexPath: string,
  mutationOptions?: UseCRUDOptions<TAttributes>
): UseCRUDReturn<TAttributes> => {
  const options = mutationOptions
    ? { ...defaultUseCRUDOptions, ...mutationOptions }
    : defaultUseCRUDOptions
  const {
    id,
    cacheKey,
    create,
    update,
    destroy,
    errorMessage,
    successMessage,
    onSuccess,
    record,
    formOptions,
    onError
  } = options

  type TRecord = BaseAPISerializer<TAttributes>
  type TDatum = DataAPISerializer<TRecord>
  type TData = DataAPISerializer<TRecord[]>
  type TRecordORDatum = DataAPISerializer<TRecord> | TRecord
  const queryClient = useQueryClient()

  const { addToast } = useToasts()

  const form = useForm<Partial<TAttributes>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: record ? (record.attributes as DeepPartial<Partial<TAttributes>>) : undefined,
    ...formOptions
  })

  const updateMutation = useMutation<
    TRecordORDatum,
    MutationError<TAttributes>,
    Partial<TAttributes>,
    () => void
  >([`${indexPath}-patch`], (params) => updateObject(`${indexPath}/${id}`, params), {
    onError(error) {
      if (error?.response?.data?.full_errors?.length) {
        addToast(error.response.data.full_errors[0], { appearance: 'error' })
      } else if (error?.response?.data?.error) {
        addToast(error.response.data.error, { appearance: 'error' })
      } else if (errorMessage) {
        addToast(errorMessage, { appearance: 'error' })
      }

      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).forEach((field) => {
          form.setError(field as Path<Partial<TAttributes>>, {
            message: error?.response?.data && error.response.data.errors[field][0]
          })
        })
      }

      if (onError) {
        onError(error)
      }
    },
    ...update,
    onSuccess(data) {
      // Setup DataAPISerializer<BaseAPISerializer<T>> if API returned a BaseAPISerializer<T> instead
      const serializedData: TDatum = (data as TDatum)?.data
        ? (data as TDatum)
        : { data: data as TRecord }

      if (cacheKey && serializedData?.data) {
        // Update object in react-query cache with updated data
        queryClient.setQueryData<TDatum>(cacheKey, serializedData)
      }

      form.reset(serializedData.data.attributes)

      if (successMessage) {
        addToast(successMessage)
      }

      onSuccess && onSuccess(serializedData)
    }
  })

  const createMutation = useMutation<
    DataAPISerializer<BaseAPISerializer<TAttributes>>,
    MutationError<TAttributes>,
    Partial<TAttributes>,
    () => void
  >([`${indexPath}-post`], (params) => createObject(indexPath, params), {
    onError(error) {
      if (error?.response?.data?.error) {
        addToast(error.response.data.error, { appearance: 'error' })
      } else if (errorMessage) {
        addToast(errorMessage, { appearance: 'error' })
      }

      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).forEach((field) => {
          form.setError(field as Path<Partial<TAttributes>>, {
            message: error?.response?.data && error.response.data.errors[field][0]
          })
        })
      }
    },
    ...create,
    onSuccess(data) {
      // Update object in react-query cache with updated data
      if (cacheKey && data) {
        queryClient.setQueryData<TData | undefined>(cacheKey, (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              data: oldData.data ? [data.data, ...oldData.data] : [data.data]
            }
          }
        })
      }

      form.reset()

      if (successMessage) {
        addToast(successMessage)
      }

      onSuccess && onSuccess(data)
    }
  })

  const destroyMutation = useMutation<void, MutationError<TAttributes>, void, () => void>(
    [`${indexPath}-delete`],
    () => destroyObject(`${indexPath}/${id}`),
    {
      onSuccess() {
        // Invalidate query data for cache key passed so it gets refetched after object is deleted
        if (cacheKey) {
          queryClient.invalidateQueries(cacheKey)
        }

        onSuccess && onSuccess()
      },
      ...destroy
    }
  )

  const isLoading =
    destroyMutation.isLoading || createMutation.isLoading || updateMutation.isLoading

  return {
    form,
    updateMutation,
    createMutation,
    destroyMutation,
    isLoading
  }
}
