import { useEffect, useState } from 'react'

import { useToasts } from 'react-toast-notifications'

import { MutationFunction, QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'

import { BaseAPISerializer } from '@/types/api'

import { useDelayedOnChange } from '.'

type UseAutoSaveFormOptions = {
  /** @default true */
  autoSave?: boolean
  /** @default 1_000 */
  autoSaveDelay?: number
  /** @default 'Houve um erro ao realizar essa ação' */
  errorMessage?: string
}

export type UseAutoSaveFormReturn<
  TObjectData extends BaseAPISerializer<Record<string, unknown>>
> = {
  formData: TObjectData
  setFormData: React.Dispatch<React.SetStateAction<TObjectData>>
  updateCallback: () => Promise<void>
  updateField: (name: keyof TObjectData['attributes'], value: unknown) => void
}

const defaultOptions: UseAutoSaveFormOptions = {
  autoSave: true,
  autoSaveDelay: 1_000,
  errorMessage: 'Houve um erro ao realizar essa ação'
}

/**
 * ```tsx
 * type Props = {
 *   dataId: number
 * }
 *
 * type DataTypeAttributes = {
 *   name: string
 * }
 *
 * type DataReturn = BaseAPISerializer<DataTypeAttributes>
 *
 * const updateFn = async (params: DataReturn) => {
 *   const { data } = await api.patch(`update/data/${params.id}`, params)
 *   return data
 * }
 *
 * const MyComponent: React.FC<Props> = ({ dataId }) => {
 *   const cacheKey = ['dataType', dataId]
 *
 *   // Get initial data from API to pass to hook
 *   const { data } = useQuery<DataAPISerializer<DataReturn>>(cacheKey)
 *
 *   // Change with `updateField` to trigger auto-save, use `formData` for current values
 *   const { formData, updateField } = useAutoSaveForm<DataReturn>(cacheKey, data?.data, updateFn)
 *
 *   const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
 *     // Trigger an auto-save passing user input to `updateField`, updating a specific field
 *     updateField(name, event.target.value)
 *   }
 *
 *   return <input value={data.attributes.name} onChange={handleChange('name')}>
 * }
 * ```
 */
export const useAutoSaveForm = <TObjectData extends BaseAPISerializer<Record<string, unknown>>>(
  /** Cache key for useQuery requests of the object data */
  cacheKey: QueryKey,
  /**
   * Object to be updated, e.g. a `job` with type `ApiJobData`, when any `job` field changes
   * the update function will be triggered if `options.autoSave` parameter is `true`
   */
  object: TObjectData,
  /** Function to pass to react-query `useMutation` to update the object */
  updateFn: MutationFunction<TObjectData, unknown>,
  options: UseAutoSaveFormOptions = defaultOptions
): UseAutoSaveFormReturn<TObjectData> => {
  const [formData, setFormData] = useState(object)
  const queryClient = useQueryClient()
  const { addToast } = useToasts()

  // Merge default options with passed options, if any were passed
  options = { ...defaultOptions, ...options }

  const updateMutation = useMutation(['autoSaveUpdateMutation'], updateFn, {
    async onMutate(params: TObjectData['attributes']) {
      await queryClient.cancelQueries(cacheKey)
      const previousData = queryClient.getQueryData<TObjectData>(cacheKey)

      queryClient.setQueryData<TObjectData | undefined>(cacheKey, (oldObject) => {
        if (!oldObject) {
          return oldObject
        }

        return {
          ...oldObject,
          attributes: {
            ...oldObject.attributes,
            ...params
          }
        }
      })

      return { previousData }
    },
    onError(_err, _data, context?: { previousData?: TObjectData }) {
      // Rollback cache data on error
      addToast(options.errorMessage, { appearance: 'error' })
      if (context?.previousData) {
        queryClient.setQueryData(cacheKey, context.previousData)

        // Prevent new auto-save request from setFormData when no data is changed
        if (JSON.stringify(context.previousData) !== JSON.stringify(formData)) {
          setFormData(context.previousData)
        }
      }
    },
    onSettled() {
      // Refetch to update possibly stale data
      queryClient.invalidateQueries(cacheKey)
    }
  })

  const updateCallback = async () => {
    await updateMutation.mutateAsync(formData as TObjectData['attributes'])
  }

  useDelayedOnChange(formData, updateCallback, options.autoSaveDelay, options.autoSave)

  const updateField = (name: keyof TObjectData['attributes'], value: unknown) => {
    setFormData((old) => ({
      ...old,
      attributes: { ...old.attributes, [name]: value }
    }))
  }

  useEffect(() => {
    // Set default values if initial object is undefined (from an unfinished request)
    if (!formData && !!object) {
      setFormData(object)
    }
  }, [object, formData, setFormData])

  return {
    formData,
    setFormData,
    updateCallback,
    updateField
  }
}

export const useDebounce = <T>(value: T, delay: number, onChange: (value: T) => void): T => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      if (value !== debouncedValue) {
        setDebouncedValue(value)
        onChange(value)
      }
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay, onChange, debouncedValue])

  return debouncedValue
}
