import { useMemo } from 'react'

import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'

import { Autocomplete, Button, Skeleton, TextField, Typography } from '@mui/material'
import { GridToolbarQuickFilter } from '@mui/x-data-grid'

import { useToggle } from 'yu-open-lib'

import { useCurrentUser } from '@/hooks/auth'
import { OptionSupportTabble, SupportFilter, SupportTicketStatus } from '@/types/support_ticket'

import SupportDialog from '../CreateSupportDialog'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 10px;
`

const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
`

type Props = {
  filters: Partial<SupportFilter>
  setFilters: (filters: Partial<SupportFilter>) => void
}

const SupportFilters: React.FC<Props> = ({ filters, setFilters }) => {
  const { isUserAdmin, user } = useCurrentUser()
  const [dialogToggle, setDialogToggle] = useToggle(false)

  const { data: brandsOptions, isInitialLoading: isLoadingBrands } = useQuery<
    OptionSupportTabble[]
  >(['brands', 'team_brands'])
  const { data: statusOptions, isInitialLoading: isLoadingStatus } = useQuery<
    OptionSupportTabble[]
  >(['support_ticket', 'status'])

  const brandName = useMemo(() => {
    const brand = brandsOptions?.find(({ value }) => value === filters.brand_id)
    return brand?.label?.toLowerCase().replace('ê', 'e')
  }, [brandsOptions, filters?.brand_id])

  const { data: usersOptions } = useQuery<OptionSupportTabble[]>([
    'users',
    'users_options',
    { responsible_company: brandName ? brandName : null, role: 'team', is_consultant: true }
  ])

  const selectedUser = useMemo(() => {
    const user = usersOptions?.find((opt) => opt.value === filters.user_id) || null
    return user
  }, [usersOptions, filters.user_id])

  const selectedStatus = useMemo(() => {
    const status = statusOptions?.find((opt) => opt.value === filters.status) || null
    return status
  }, [statusOptions, filters.status])

  const setBrandFilter = (option: OptionSupportTabble | null) => {
    if (selectedUser) {
      setFilters({
        ...filters,
        user_id: Number(selectedUser.value),
        brand_id: option ? Number(option.value) : null
      })
    }
    if (!selectedUser) {
      setFilters({
        ...filters,
        user_id: null,
        brand_id: option ? Number(option.value) : null
      })
    }
  }

  const sortedUsersOptions = useMemo(() => {
    const sortedList = usersOptions?.sort((a, b) => {
      const aName = a.label ? a.label : ''
      const bName = b.label ? b.label : ''
      if (aName < bName) return -1
      if (aName > bName) return 1
      return 0
    })
    return sortedList
  }, [usersOptions])

  return (
    <MainContainer>
      <Typography>Filtros</Typography>
      <FiltersContainer>
        <SupportDialog open={dialogToggle} onClose={setDialogToggle} user={user} />
        {isLoadingStatus ? (
          <Skeleton variant="text" width="100%" height={20} />
        ) : (
          <Autocomplete
            size="small"
            value={
              filters.status
                ? ({
                    label: selectedStatus?.label,
                    value: selectedStatus?.value
                  } as OptionSupportTabble)
                : undefined
            }
            options={statusOptions || []}
            sx={{ width: 160 }}
            onChange={(_event, option: OptionSupportTabble | null, reason) => {
              if (reason === 'clear') {
                setFilters({ ...filters, status: undefined })
              } else {
                setFilters({
                  ...filters,
                  status: option ? (option.value as SupportTicketStatus) : undefined
                })
              }
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Status" />}
          />
        )}
        {isUserAdmin && (
          <>
            {isLoadingBrands ? (
              <Skeleton variant="text" width="100%" height={20} />
            ) : (
              <>
                <Autocomplete
                  value={selectedUser}
                  options={sortedUsersOptions || []}
                  onChange={(_event, option: OptionSupportTabble | null, reason) => {
                    if (reason === 'clear') {
                      setFilters({ ...filters, user_id: null })
                    } else {
                      setFilters({ ...filters, user_id: option ? Number(option.value) : null })
                    }
                  }}
                  size="small"
                  sx={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField label="Membro" variant="outlined" {...params} />
                  )}
                />
                <Autocomplete
                  value={brandsOptions?.find((option) => option.value === filters.brand_id) || null}
                  options={brandsOptions || []}
                  onChange={(_event, option: OptionSupportTabble | null, reason) => {
                    if (reason === 'clear') {
                      setFilters({ ...filters, brand_id: null })
                    } else {
                      setBrandFilter(option)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Empresa" />
                  )}
                  size="small"
                  sx={{ width: 250 }}
                />
              </>
            )}
          </>
        )}

        <Button onClick={setDialogToggle} variant="outlined">
          Nova solicitação
        </Button>
        <GridToolbarQuickFilter
          sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '0' }}
        />
      </FiltersContainer>
    </MainContainer>
  )
}

export default SupportFilters
