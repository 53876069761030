import api from '@/api/apiToken'
import { history } from '@/routing'
import { APIYuriUserData } from '@/types/users'

import {
  GITHUB_CLIENT_ID,
  GITHUB_REDIRECT_PATH,
  GOOGLE_CLIENT_ID,
  GOOGLE_REDIRECT_PATH,
  LINKEDIN_REDIRECT_PATH,
  MICROSOFT_CLIENT_ID,
  MICROSOFT_OAUTH_SCOPE,
  MICROSOFT_REDIRECT_PATH
} from './constant'

interface SignInResponse {
  authentication_token: string
  email: string
}

export interface CurrentUserResponse {
  data: APIYuriUserData
}

export const signInWithLinkedIn = async (accessToken: string): Promise<void> => {
  await api.post<SignInResponse>('auth/linkedin_oauth', {
    access_token: accessToken,
    redirect_uri: `${window.location.origin}${LINKEDIN_REDIRECT_PATH}`
  })
}

export const signInWithGoogle = async (accessToken: string): Promise<void> => {
  await api.post<SignInResponse>(
    'auth/google_oauth2',
    {
      access_token: accessToken,
      client_id: GOOGLE_CLIENT_ID,
      redirect_uri: `${window.location.origin}${GOOGLE_REDIRECT_PATH}`
    },
    {
      withCredentials: true
    }
  )
}

export const signInWithMicrosoft = async (accessToken: string): Promise<void> => {
  const { data } = await api.post<SignInResponse>('auth/microsoft_oauth', {
    access_token: accessToken,
    client_id: MICROSOFT_CLIENT_ID,
    scope: MICROSOFT_OAUTH_SCOPE,
    redirect_uri: `${window.location.origin}${MICROSOFT_REDIRECT_PATH}`
  })

  window.postMessage(
    {
      type: 'AUTH_SUCCESS',
      payload: {
        token: data.authentication_token,
        email: data.email
      }
    },
    '*'
  )
}

export const signInWithGitHub = async (accessToken: string): Promise<void> => {
  await api.post<SignInResponse>('auth/github_oauth', {
    access_token: accessToken,
    client_id: GITHUB_CLIENT_ID,
    redirect_uri: `${window.location.origin}${GITHUB_REDIRECT_PATH}`
  })
}

export const logout = async (): Promise<void> => {
  await api.delete('auth/logout')
  history.push('/users/sign_in')
}

export const getCurrentUser = async (): Promise<CurrentUserResponse> => {
  const { data } = await api.get<CurrentUserResponse>('users/current')

  return data
}
