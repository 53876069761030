import { useMemo, useState } from 'react'

import { Link } from 'react-router-dom'

import ListIcon from '@mui/icons-material/List'
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

import YuriBlackLogo from '@/assets/images/yuri_black.png'
import { YuriNavbarItem, useNavBarItems } from '@/hooks'

import DrawerAccordion from './DrawerAccordion'

type ListItemsProps = {
  buttons: YuriNavbarItem[]
  onClick?: () => void
}

const ListItems: React.FC<ListItemsProps> = ({ buttons, onClick }) => (
  <>
    {buttons.map(({ path, icon, text, ...props }) => (
      <ListItem component={Link} button key={path} to={path} onClick={onClick} {...props}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    ))}
  </>
)

type SectionItems = {
  more: YuriNavbarItem[]
  withoutGroup: YuriNavbarItem[]
  downItems: YuriNavbarItem[]
}

type Props = {
  open: boolean
  handleClose: () => void
}

const Sections: React.FC<{ items: SectionItems; handleClose: () => void }> = ({
  items,
  handleClose
}) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<unknown>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      {!!items.more.length && (
        <DrawerAccordion
          titleIcon={<ListIcon />}
          title="Mais"
          expanded={expanded}
          handleChange={handleChange}
        >
          <ListItems buttons={items.more} onClick={handleClose} />
        </DrawerAccordion>
      )}
    </>
  )
}

const YuDrawer: React.FC<Props> = ({ open, handleClose }) => {
  const { drawerItems } = useNavBarItems()

  const items = useMemo(() => {
    const more = drawerItems.filter((item) => {
      return item.group == 'more'
    })

    const downItems = drawerItems.filter((item) => {
      return item.group == 'downItems'
    })

    const withoutGroup = drawerItems.filter((item) => {
      return !item.group
    })

    return {
      up: { more, withoutGroup, downItems },
      down: { more: [], withoutGroup: [], downItems: [] }
    }
  }, [drawerItems])

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleClose}
      // TODO: 'permanent' variant hides content in some pages
      // https://material-ui.com/components/drawers/#persistent-drawer
      // should push them instead
      // variant={isWide ? 'permanent' : 'temporary'}
      variant="temporary"
    >
      <List>
        <ListItem button component={Link} to="/" onClick={handleClose}>
          <img src={YuriBlackLogo} width="32" height="32" />
        </ListItem>

        <ListItems buttons={items.up.withoutGroup} onClick={handleClose} />

        <Sections items={items.up} handleClose={handleClose} />
        <ListItems buttons={items.up.downItems} onClick={handleClose} />
      </List>

      <List>
        <Sections items={items.down} handleClose={handleClose} />
      </List>
    </Drawer>
  )
}

export default YuDrawer
